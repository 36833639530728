import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DataGrid } from '@mui/x-data-grid';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import {
  CrawlerFindingTokenType,
  useDownvoteFindingsTokenMutation,
  useGetCompanySizeQuery,
  useGetCrawlerFindingTokensQuery,
  useSetCompanySizeMutation,
  useUpvoteFindingsTokenMutation,
} from '../../../../gql/generated/graphql';
import { getFirstNode, getNodes } from '../../../../gql/helpers';
import { CompanySizeType } from '../../types';

type Props = {
  companyId: number;
};

const ValidateSizeStep = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const {
    data: crawlerFinding,
    loading: crawlerFindingLoading,
    refetch: refetchCrawlerFindingTokens,
  } = useGetCrawlerFindingTokensQuery({
    variables: { companyId },
  });
  const [setCompanySize, { loading: isSetCompanySizeLoading }] = useSetCompanySizeMutation();

  const { data: companySize, refetch: refetchGetCompanySize } = useGetCompanySizeQuery({
    variables: { companyId },
  });

  const crawlerFindingsNodes: CrawlerFindingTokenType[] = getNodes(
    crawlerFinding?.getCrawlerFindingTokens,
  );
  const companySizeNode = getFirstNode(companySize?.getCompany);

  useEffect(() => {
    setUrl(crawlerFindingsNodes?.[0]?.url);
  }, []);

  const [url, setUrl] = useState('');
  const [findingUrl, setFindingUrl] = useState('');
  const [fte, setFte] = useState('');
  const [fteType, setFteType] = useState<number>(null);

  const [upVoteMutation, { loading: upVoteLoading }] = useUpvoteFindingsTokenMutation();
  const [downVoteMutation, { loading: downVoteLoading }] = useDownvoteFindingsTokenMutation();

  const columns = [
    { field: 'fte', headerName: t('validation.fte'), width: 100 },
    { field: 'url', headerName: t('validation.url'), width: 300 },
    {
      field: 'actions',
      headerName: '',
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            disabled={upVoteLoading}
            size="small"
            onClick={() => handleUpVote(params.row)}
          >
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton
            disabled={downVoteLoading}
            size="small"
            onClick={() => handleDownVote(params.row)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => handleShow(params.row)}>
            <ArrowForwardIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleUpVote = async (row) => {
    const result = await upVoteMutation({
      variables: {
        tokenId: row.id,
      },
    });
    if (result?.data?.upvoteFindingsToken?.ok) {
      await refetchGetCompanySize();
    }
  };

  const handleDownVote = async (row) => {
    const result = await downVoteMutation({
      variables: {
        tokenId: row.id,
      },
    });
    if (result?.data?.downvoteFindingsToken?.ok) {
      await refetchGetCompanySize();
    }
  };

  const handleShow = (row) => {
    if (!row?.url) return;
    setUrl(row?.url);
  };

  const handleSetCompanySize = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!companyId || !fte) return;

    const result = await setCompanySize({
      variables: {
        companyId,
        fte: parseInt(fte),
        findingUrl,
        typeOfFinding: fteType,
      },
    });

    if (result?.data?.setCompanySize?.ok) {
      setFindingUrl('');
      setFte('');
      setFteType(null);
      await refetchCrawlerFindingTokens();
      await refetchGetCompanySize();
    }
  };

  const canSubmit = () => {
    if (fteType === CompanySizeType.IMAGE || fteType === CompanySizeType.TEXT) {
      return Boolean(fte && findingUrl);
    }
    if (fteType === CompanySizeType.LINKEDIN || fteType === CompanySizeType.OTHER) {
      return Boolean(fte);
    }
    return false;
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Typography fontWeight="medium">
        {t('validation.currentSizeText')}
        {` ${companySizeNode?.maxFte || ''}`}
      </Typography>
      <Typography fontWeight="medium">
        {t('validation.linkedInFteText')}
        {` ${companySizeNode?.linkedinFte || ''}`}
      </Typography>
      <Stack mt={2} direction={{ xs: 'column', md: 'row' }} gap={1}>
        <DataGrid
          disableRowSelectionOnClick
          loading={crawlerFindingLoading}
          rows={crawlerFindingsNodes}
          columns={columns}
          rowHeight={35}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
        <Box flex={1} display="flex">
          <iframe width="100%" height="100%" title="fte" src={url} />
        </Box>
      </Stack>
      <Box mt={2} component="form" onSubmit={handleSetCompanySize} noValidate autoComplete="off">
        <Typography>Set size manually</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              label={fteType === CompanySizeType.LINKEDIN ? 'LinkedIn URL' : 'URL'}
              variant="outlined"
              name="url"
              value={findingUrl}
              onChange={(e) => setFindingUrl(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              label="FTE"
              variant="outlined"
              name="fte"
              value={fte}
              type="number"
              onChange={(e) => setFte(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl>
              <FormLabel id="fte_type">Type</FormLabel>
              <RadioGroup
                value={fteType}
                onChange={(e) => setFteType(parseInt(e.target.value))}
                row
                aria-labelledby="fte-type"
                name="fte-type"
              >
                <FormControlLabel value={1} control={<Radio />} label="Image" />
                <FormControlLabel value={2} control={<Radio />} label="Text" />
                <FormControlLabel value={4} control={<Radio />} label="Linkedin" />
                <FormControlLabel value={3} control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={isSetCompanySizeLoading || !canSubmit()}
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
      <CustomBackdrop open={isSetCompanySizeLoading || upVoteLoading || downVoteLoading} />
    </Box>
  );
};

export default ValidateSizeStep;
//
