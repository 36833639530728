import { useTranslation } from 'react-i18next';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';

import { ValidationSteps } from '../../../ValidationPage/types';

import styles from './styles';

type Props = {
  step: ValidationSteps;
  onPress: (step: ValidationSteps) => {};
};

const EditButton = ({ step, onPress }: Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={styles.editButton}>
      <IconButton color="inherit" aria-label="edit" onClick={() => onPress(step)}>
        <Tooltip title={t('assessment.editButtonTooltip')}>
          <EditOutlinedIcon />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default EditButton;
