import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  button: {
    minWidth: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
};

export default styles;
