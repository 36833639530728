import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, Button, CircularProgress, TextField } from '@mui/material';

import { SoftwareCategoryEdge } from '../../../../gql/generated/graphql';

import styles from './styles';

type Props = {
  options: SoftwareCategoryEdge[];
  loading: boolean;
  searchTerm: string;
  onOptionSelect: (_: React.SyntheticEvent, value: SoftwareCategoryEdge | null) => void;
  onInputChange: (_: React.SyntheticEvent, value: string, reason: string) => void;
};

const QuickSearch = ({ options, loading, searchTerm, onInputChange, onOptionSelect }: Props) => {
  const { t } = useTranslation();

  const getOptionLabel = (option: SoftwareCategoryEdge) => {
    if (!option?.node) {
      return ''; // or any fallback string if 'node' is undefined
    }
    const { subCategory1, subCategory2, subCategory3, subCategory4 } = option.node;
    const subCategories = [subCategory1, subCategory2, subCategory3, subCategory4].filter(
      (subCategory) => subCategory !== '',
    );
    return subCategories.join(' - ');
  };
  return (
    <Box display="flex" alignItems="center" flex={1}>
      <Autocomplete
        freeSolo
        sx={styles.autocomplete}
        loading={loading}
        getOptionLabel={getOptionLabel}
        options={options}
        size="small"
        inputValue={searchTerm}
        onInputChange={onInputChange}
        onChange={onOptionSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('validation.quickSearch')}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default QuickSearch;
