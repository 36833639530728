import React, { useState } from 'react';
import { FileManagerComponent, FileOpenEventArgs } from '@syncfusion/ej2-react-filemanager';
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from '@syncfusion/ej2-react-pdfviewer';
import { Dialog, Typography } from '@mui/material';

const CompanyFilesPage = () => {
  const hostUrl = 'https://ej2-aspcore-service.azurewebsites.net/';
  const [pdfFileUrl, setPdfFileUrl] = useState<string | null>(null);

  // Handle file open event
  const handleFileOpen = (args: any) => {
    console.log('File open event triggered:', args);

    // Check if `isFile` is true
    if (args.isFile) {
      console.log('This is a file.');
    } else {
      console.log('This is not a file.');
    }

    // Check if `type` is `.pdf`
    if (args.fileDetails.type === '.pdf') {
      console.log('File is of type PDF.');
    } else {
      console.log(`File is not of type PDF; it is of type ${args.type}.`);
    }

    // Proceed if both conditions are true
    if (args.fileDetails.isFile && args.fileDetails.type === '.pdf') {
      console.log('PDF file detected:', args.fileDetails.name);

      // Example Base64 string for testing
      const filePath = `${hostUrl}api/FileManager/FileOperations?path=${encodeURIComponent(args.filterPath + args.name)}`;
      setPdfFileUrl('https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf');
      console.log('PDF file data set in state to open PDF viewer:', filePath);
    }
  };

  // Function to close the PDF viewer
  const closePdfViewer = () => {
    setPdfFileUrl(null);
    console.log('PDF viewer closed.');
  };

  return (
    <div className="company-files-page">
      <Typography variant="h4" gutterBottom>
        Company Files
      </Typography>

      <div className="control-section">
        <FileManagerComponent
          id="file-manager"
          ajaxSettings={{
            url: hostUrl + 'api/FileManager/FileOperations',
          }}
          fileOpen={handleFileOpen} // Attach fileOpen handler
        />
      </div>

      {/* Dialog to display PDF Viewer */}
      <Dialog open={!!pdfFileUrl} onClose={closePdfViewer} maxWidth="lg" fullWidth>
        {pdfFileUrl && (
          <PdfViewerComponent
            id="pdf-viewer"
            documentPath="https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf" // Pass the URL to the PDF file here
            serviceUrl="https://services.syncfusion.com/react/production/api/pdfviewer"
            style={{ height: '640px' }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                Annotation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
        )}
      </Dialog>
    </div>
  );
};

export default CompanyFilesPage;
