import { useTranslation } from 'react-i18next';

import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import CustomIconButton from '../CustomIconButton/CustomIconButton';

type Props = {
  isRelevant: boolean;
  onAdd: () => void;
  onRemove: () => void;
};

const RelevantButton = ({ isRelevant, onAdd, onRemove }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {isRelevant ? (
        <CustomIconButton
          onClick={onRemove}
          tooltip={t('assessment.removeFromRelevant')}
          ariaLabel="Remove from relevant"
          icon={<StarIcon />}
        ></CustomIconButton>
      ) : (
        <CustomIconButton
          onClick={onAdd}
          tooltip={t('assessment.addToRelevenat')}
          ariaLabel="Add to relevant"
          icon={<StarOutlineIcon fontSize="medium" />}
        ></CustomIconButton>
      )}
    </>
  );
};

export default RelevantButton;
