import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

import useOpenAI from '../../hooks/useOpenAI';

import styles from './styles';

type Props = {
  onSicCodeChange: (sicCode: string) => void;
};

const SicCodeOpenAi = ({ onSicCodeChange }: Props) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState('');
  const { response, loading, error, askOpenAI } = useOpenAI();

  const responseRef = useRef(null);
  const errorRef = useRef(null);

  useEffect(() => {
    if (response) {
      onSicCodeChange(response);
    }
  }, [response, onSicCodeChange]);

  useEffect(() => {
    if (response && responseRef?.current) {
      responseRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (error && errorRef?.current) {
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [response, error]);

  const handleSubmit = async () => {
    if (inputText.trim()) {
      await askOpenAI(inputText);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography>{t('validation.validateCustomers.openAISicCodesTitle')}</Typography>

      <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
        <TextField
          label="Industry"
          variant="outlined"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          size="small"
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          size="small"
          disabled={loading || !inputText.trim()}
          sx={styles.button}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            t('validation.validateCustomers.openAISicCodeButtonLabel')
          )}
        </Button>
      </Box>

      {response && (
        <Typography variant="h6" sx={{ marginTop: 2 }} ref={responseRef}>
          {`${t('validation.validateCustomers.openAIResponseSicCodeLabel')} ${response}`}
        </Typography>
      )}
      {error && (
        <Typography color="error" variant="body1" ref={errorRef}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default SicCodeOpenAi;
