import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import ProductCategorySelect from '../../../../components/ProductCategorySelect/ProductCategorySelect';
import { ProductCategory } from '../../../../components/ProductCategorySelect/types';
import {
  useAddSoftwareCategoryToCompanyMutation,
  useGetProductCategoriesForCompanyQuery,
  useInsertCertainProductCategoryMutation,
  useProductCategoryListQuery,
  useProductSubCategory3ListLazyQuery,
  useProductSubCategoryListLazyQuery,
  useSetProductCategoryToZeroMutation,
} from '../../../../gql/generated/graphql';
import { getNodes } from '../../../../gql/helpers';
import { ProductCategories } from '../../types';

import { validateProductCategoryStyles } from './styles';

type Props = {
  companyId: number;
};

const ValidateProductCategory = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const [productCategory, setProductCategory] = useState<ProductCategory>({
    category: null,
    subCategory: null,
    subCategory3: null,
    categoryId: null,
  });

  const {
    data: productCategoriesForCompany,
    loading: productCategoriesForCompanyLoading,
    refetch: refetchProductCategories,
  } = useGetProductCategoriesForCompanyQuery({
    variables: { companyId },
  });
  const { data: productCategoryList, loading: productCategoryListLoading } =
    useProductCategoryListQuery();
  const [
    getProductSubCategoryList,
    { data: productSubCategoryList, loading: productSubCategoryListLoading },
  ] = useProductSubCategoryListLazyQuery();

  const [
    getProductSubCategory3List,
    { data: productSubCategory3List, loading: productSubCategory3ListLoading },
  ] = useProductSubCategory3ListLazyQuery();

  const [insertCertainProductCategory, { loading: insertCertainProductCategoryLoading }] =
    useInsertCertainProductCategoryMutation();
  const [setProductCategoryToZero, { loading: setProductCategoryToZeroLoading }] =
    useSetProductCategoryToZeroMutation();
  const [addSoftwareCategoryToCompany, { loading: addSoftwareCategoryToCompanyLoading }] =
    useAddSoftwareCategoryToCompanyMutation();

  const productCategoriesForCompanyNodes =
    productCategoriesForCompany?.getCompany?.edges?.[0]?.node?.productCategorization?.edges?.map(
      (node) => ({
        ...node?.node?.softwareCategory,
        dbId: node?.node?.dbId,
        id: node?.node?.id,
        softwareCategoryCertainty: node?.node?.softwareCategoryCertainty,
      }),
    );

  const productCategoryListNodes = getNodes(productCategoryList?.productCategoryList);
  const productSubCategoryListNodes = getNodes(productSubCategoryList?.productCategoryList);
  const productSubCategory3ListNodes = getNodes(productSubCategory3List?.productCategoryList);

  const productCategoryColumns = [
    {
      field: 'subCategory1',
      headerName: t('validation.categoryColumnLabel'),
      width: 200,
    },
    {
      field: 'subCategory2',
      headerName: t('validation.subCategoryColumnLabel'),
      width: 200,
    },
    {
      field: 'subCategory3',
      headerName: t('validation.subCategory3ColumnLabel'),
      width: 200,
    },
    {
      field: 'softwareCategoryCertainty',
      headerName: t('validation.scoreColumnLabel'),
      width: 100,
      valueFormatter: (value: number) => {
        return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
      },
    },
    { field: 'g2Url', headerName: t('validation.g2Url'), width: 200 },
    {
      field: 'actions',
      headerName: '',
      width: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton size="small" onClick={() => handleOk(params.row)}>
            <Tooltip title={t('validation.ok')}>
              <CheckCircleOutlineIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton size="small" onClick={() => handleVoid(params.row)}>
            <Tooltip title={t('validation.void')}>
              <RadioButtonUncheckedIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const handleOk = async (row) => {
    const result = await insertCertainProductCategory({
      variables: { productCategorizationId: row.dbId },
    });
    if (result?.data?.insertCertainProductCategory?.success) {
      await refetchProductCategories();
    }
  };
  const handleVoid = async (row) => {
    const result = await setProductCategoryToZero({
      variables: { productCategorizationId: row.dbId },
    });
    if (result?.data?.setProductCategoryToZero?.success) {
      await refetchProductCategories();
    }
  };

  const handleProductCategoryChange = async (name: string, value: string | null) => {
    if (!value) return;

    const [category, categoryId] = value.includes('-') ? value.split('-') : [value, null];
    setProductCategory((prevState: ProductCategory) => ({
      ...prevState,
      [name]: category,
      categoryId: parseInt(categoryId) || null,
    }));
    if (name === ProductCategories.CATEGORY) {
      await getProductSubCategoryList({ variables: { subCategory1: category } });
      setProductCategory((prevState) => ({
        ...prevState,
        category: value,
        subCategory: null,
        subCategory3: null,
      }));
    }
    if (name === ProductCategories.SUB_CATEGORY) {
      await getProductSubCategory3List({ variables: { subCategory2: category } });
      setProductCategory((prevState) => ({
        ...prevState,
        subCategory: value,
        subCategory3: null,
      }));
    }
    if (name === ProductCategories.SUB_CATEGORY3) {
      setProductCategory((prevState) => ({
        ...prevState,
        subCategory3: value,
      }));
    }
  };

  const handleSetCategory = async () => {
    const result = await addSoftwareCategoryToCompany({
      variables: {
        companyId,
        softwareCategoryId: productCategory.categoryId,
      },
    });

    if (result?.data?.addSoftwareCategoryToCompany?.success) {
      setProductCategory({
        category: null,
        subCategory: null,
        subCategory3: null,
        categoryId: null,
      });
      await refetchProductCategories();
    }
  };

  const handleSetProductCategoryId = (categoryId: number) => {
    setProductCategory((prevState: ProductCategory) => ({
      ...prevState,
      categoryId,
    }));
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <DataGrid
        sx={validateProductCategoryStyles.dataGrid}
        loading={productCategoriesForCompanyLoading}
        disableRowSelectionOnClick
        rows={productCategoriesForCompanyNodes || []}
        columns={productCategoryColumns}
        rowHeight={35}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
      />
      <ProductCategorySelect
        categories={productCategoryListNodes}
        subCategories={productSubCategoryListNodes}
        subCategories3={productSubCategory3ListNodes}
        state={productCategory}
        loadingCategories={productCategoryListLoading}
        loadingSubCategories={productSubCategoryListLoading}
        loadingSubCategories3={productSubCategory3ListLoading}
        onChange={handleProductCategoryChange}
        onHandleSetCategory={handleSetCategory}
        onSetProductCategoryId={handleSetProductCategoryId}
      />
      <CustomBackdrop
        open={
          insertCertainProductCategoryLoading ||
          setProductCategoryToZeroLoading ||
          addSoftwareCategoryToCompanyLoading
        }
      />
    </Box>
  );
};

export default ValidateProductCategory;
