export const getNodes = (queryResults) => {
  return queryResults?.edges?.map(({ node }) => node) || [];
};

export const getFirstNode = (queryResults) => {
  return queryResults?.edges?.at(0)?.node;
};

export const getLastNode = (queryResults) => {
  return queryResults.edges?.at(-1)?.node;
};
