import { useTranslation } from 'react-i18next';

import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';

import styles from './styles';

const CustomFooter = ({ onDeleteSelected, onRefresh }) => {
  const { t } = useTranslation();
  return (
    <GridFooterContainer>
      <Box>
        <IconButton onClick={onDeleteSelected} size="small">
          <Tooltip title={t('validation.validateKeyword.deleteSelectedIconTooltip')}>
            <DeleteOutlineIcon fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton onClick={onRefresh} size="small">
          <Tooltip title={t('validation.validateKeyword.refreshIconTooltip')}>
            <CachedIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </Box>
      <GridFooter sx={styles.gridFooter} />
    </GridFooterContainer>
  );
};

export default CustomFooter;
