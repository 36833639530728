import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as XLSX from 'xlsx';

import {
  useExcelUploadFileMutation,
  useExcelUploadRowMutation,
  useExcelUploadSheetMutation,
  useFinishExcelFileUploadMutation,
} from '../../../gql/generated/graphql';
import { useSnackbar } from '../../../providers/SnackbarProvider';

type UploadExcelFileProps = {
  fileContents: ArrayBuffer | null;
  companyId: number;
  fileName: string;
  uploaderName: string;
  disableUpload?: boolean;
  onProgress?: (progress: number) => void;
  onSheetCompletion?: (sheetName: string) => void;
  onFileCompletion?: (fileID: number) => void;
};

const UploadExcelFile = ({
  fileContents,
  companyId,
  fileName,
  uploaderName,
  disableUpload = false,
  onProgress,
  onSheetCompletion,
  onFileCompletion,
}: UploadExcelFileProps) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadExcelFile, { loading: uploadExcelFileLoading }] = useExcelUploadFileMutation();
  const [uploadExcelSheet, { loading: uploadExcelSheetLoading }] = useExcelUploadSheetMutation();
  const [uploadExcelRow, { loading: uploadExcelRowLoading }] = useExcelUploadRowMutation();
  const [finishFileUpload, { loading: fileUploadingMutationLoading }] =
    useFinishExcelFileUploadMutation();

  const sheetsToUpload: string[] = [
    'I_Steering',
    'PnL',
    'BS',
    'FTE',
    'Customers_aggregated',
    'Customers_list',
    'Pricing',
  ];

  const handleExcelUpload = async () => {
    if (!fileContents) {
      console.error('No file contents provided');
      return;
    }
    setIsFileUploading(true);

    const workbook = XLSX.read(new Uint8Array(fileContents), { type: 'array' });
    const totalSheets = workbook.SheetNames.length;
    let totalProgress = 0;

    // Step 1: Upload the file and get the fileId
    const fileResponse = await uploadExcelFile({
      variables: {
        input: {
          companyId,
          fileName,
          uploaderName,
          isBenchmarkingFile: true,
          isCustomerFile: false,
          isLboFile: false,
        },
      },
    });

    const fileId = fileResponse.data?.uploadExcelFile?.file?.id;
    if (!fileResponse.data?.uploadExcelFile?.ok) {
      console.error('Failed to create file record');
      showSnackbar('Failed to create file record', 'error', false);
      setIsFileUploading(false);
      return;
    }
    console.log('File with ID {fileID} created successfully');

    if (!fileId) {
      console.error('Failed to create file record');
      showSnackbar('Failed to create file record', 'error', false);
      setIsFileUploading(false);
      return;
    }

    // Step 2: Upload sheets and rows
    const allMyPromises = [];
    for (let i = 0; i < totalSheets; i++) {
      const sheetName = workbook.SheetNames[i];
      const sheet = workbook.Sheets[sheetName];

      if (!sheetsToUpload.includes(sheetName)) {
        continue; // Skip sheets not in the list
      }
      // Step 2a: Upload the sheet
      console.log(`Uploading sheet: ${sheetName}`);
      const sheetResponse = await uploadExcelSheet({
        variables: {
          input: {
            excelFileId: Number(fileId),
            sheetName: sheetName,
          },
        },
      });

      const sheetId = sheetResponse.data?.uploadExcelSheet?.sheet?.id;

      if (!sheetId) {
        console.error('Failed to create sheet');
        showSnackbar('Failed to create sheet', 'error', false);
        continue;
      }

      // Step 2b: Upload non-empty rows in the sheet
      const rows: (string | number | null)[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const totalRows = rows.length;

      for (let rowIndex = 0; rowIndex < totalRows; rowIndex++) {
        const row = rows[rowIndex];
        const nonEmptyCells = row.filter(
          (cell) => cell !== null && cell !== undefined && cell !== '',
        );

        if (nonEmptyCells.length === 0) {
          continue; // Skip empty rows
        }

        const myPromise = uploadExcelRow({
          variables: {
            input: {
              sheetId: Number(sheetId),
              row: rowIndex + 1,
              cells: row.map((cell) => (cell !== null && cell !== undefined ? String(cell) : '')),
            },
          },
        });
        allMyPromises.push(myPromise);

        // Update progress and call the onProgress callback if provided
        if (onProgress) {
          totalProgress += 100 / (totalSheets * totalRows);
          onProgress(Math.min(totalProgress, 100));
        }
      }

      // Call the onSheetCompletion callback if provided
      if (onSheetCompletion) {
        onSheetCompletion(sheetName);
      }
    }
    // Wait for all promises to resolve
    await Promise.all(allMyPromises).catch(() => {
      showSnackbar('Failed to upload rows', 'error', false);
    });
    // mark the file as completed via the mutation:
    const finishFileResponse = await finishFileUpload({
      variables: { input: parseInt(fileId, 10) }, // Ensure fileId is an integer
    });
    if (!finishFileResponse?.data?.finishExcelFileUpload?.ok) {
      showSnackbar('Failed to finish file upload', 'error', false);
    }

    // Call the onFileCompletion callback if provided
    if (onFileCompletion) {
      onFileCompletion(Number(fileId));
    }
    console.log('File with ID {fileID} created successfully');
    setIsFileUploading(false);
  };

  const isUploading =
    isFileUploading ||
    fileUploadingMutationLoading ||
    uploadExcelFileLoading ||
    uploadExcelSheetLoading ||
    uploadExcelRowLoading;

  return (
    <div>
      <Button
        component="label"
        fullWidth
        variant="contained"
        disabled={isUploading || disableUpload}
        sx={{ flexGrow: 1 }}
        onClick={handleExcelUpload}
      >
        {isUploading ? (
          <Box display="flex" gap={2}>
            <Typography>{t('benchmarking.uploadFileLoadingIndicator')}</Typography>
            <CircularProgress size={20} color="secondary" />
          </Box>
        ) : (
          t('benchmarking.uploadToDBButton')
        )}
      </Button>
    </div>
  );
};

export default UploadExcelFile;
