import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';

import { SicCodeEdge } from '../../../../gql/generated/graphql';

import styles from './styles';

type Props = {
  options: SicCodeEdge[];
  loading: boolean;
  searchTerm: string;
  onOptionSelect: (_: React.SyntheticEvent, value: SicCodeEdge | null) => void;
  onInputChange: (_: React.SyntheticEvent, value: string, reason: string) => void;
};

const SicCodesSearch = ({ options, loading, searchTerm, onInputChange, onOptionSelect }: Props) => {
  const { t } = useTranslation();

  const getOptionLabel = (option: SicCodeEdge) => {
    const { sicCodeStr, description } = option?.node;
    return `${description} ${sicCodeStr}`;
  };
  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        freeSolo
        sx={styles.autocomplete}
        loading={loading}
        getOptionLabel={getOptionLabel}
        options={options}
        size="small"
        inputValue={searchTerm}
        onInputChange={onInputChange}
        onChange={onOptionSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('validation.validateCustomers.addGenericSicCodesSelectLabel')}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default SicCodesSearch;
