import { useState } from 'react';

import {
  useProductCategoryListQuery,
  useProductSubCategory3ListLazyQuery,
  useProductSubCategoryListLazyQuery,
} from '../gql/generated/graphql';
import { getNodes } from '../gql/helpers';

export const useProductCategoryData = () => {
  const [productCategory, setProductCategory] = useState({
    category: null,
    subCategory: null,
    subCategory3: null,
    categoryId: null,
  });

  const { data: productCategoryList, loading: productCategoryListLoading } =
    useProductCategoryListQuery();
  const [
    getProductSubCategoryList,
    { data: productSubCategoryList, loading: productSubCategoryListLoading },
  ] = useProductSubCategoryListLazyQuery();
  const [
    getProductSubCategory3List,
    { data: productSubCategory3List, loading: productSubCategory3ListLoading },
  ] = useProductSubCategory3ListLazyQuery();

  const productCategoryListNodes = getNodes(productCategoryList?.productCategoryList);
  const productSubCategoryListNodes = getNodes(productSubCategoryList?.productCategoryList);
  const productSubCategory3ListNodes = getNodes(productSubCategory3List?.productCategoryList);

  return {
    productCategory,
    setProductCategory,
    productCategoryListNodes,
    productSubCategoryListNodes,
    productSubCategory3ListNodes,
    productCategoryListLoading,
    productSubCategoryListLoading,
    productSubCategory3ListLoading,
    getProductSubCategoryList,
    getProductSubCategory3List,
  };
};
