import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  validatedChip: {
    backgroundColor: 'secondary.main',
    color: 'white',
  },
  notValidatedChip: {
    backgroundColor: '#4C6390',
    color: 'white',
  },
  validatedChipButton: {
    backgroundColor: 'white',
    borderRadius: 1,
    color: 'primary.info',
  },
  notValidatedChipButton: {
    backgroundColor: 'white',
    borderRadius: 1,
    color: 'primary.main',
  },
};

export default styles;
