import { useTranslation } from 'react-i18next';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Typography } from '@mui/material';

import CustomIconButton from '../CustomIconButton/CustomIconButton';
import RelevantButton from '../RelevantButton/RelevantButton';

type Props = {
  companyName: string;
  isRelevant: boolean;
};

const CompanyHeader = ({ companyName, isRelevant }: Props) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h6">
        {t('assessment.companyAssessment')} {companyName}
      </Typography>
      <Box display="flex" alignItems="center" gap={{ sm: 1, xs: 0 }}>
        <CustomIconButton
          onClick={() => {}}
          icon={<KeyboardArrowRightIcon />}
          tooltip={t('assessment.nextDFM')}
          ariaLabel="next DFM"
        />
        <CustomIconButton
          onClick={() => {}}
          icon={<KeyboardDoubleArrowRightIcon />}
          tooltip={t('assessment.readyToDiscuss')}
          ariaLabel="ready to be discussed"
        />
        <RelevantButton isRelevant={isRelevant} onAdd={() => {}} onRemove={() => {}} />
      </Box>
    </Box>
  );
};

export default CompanyHeader;
