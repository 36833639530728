import { useTranslation } from 'react-i18next';

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { GetBuyAndBuildPlatformsQuery } from '../../../../gql/generated/graphql';

type Props = {
  platform: string;
  platforms: GetBuyAndBuildPlatformsQuery['getBuyAndBuildPlatforms']['edges'][0]['node'][];
  onHandleChangePlatform: (event: SelectChangeEvent) => void;
};

const BIDPlatform = ({ platform, platforms, onHandleChangePlatform }: Props) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="bid-firm">
        {t('validation.finishStep.bidPlatformInputLabel')}
      </InputLabel>
      <Select
        size="small"
        labelId="bid-platform"
        id="bid-platform"
        value={platform}
        label="BID Platform"
        onChange={onHandleChangePlatform}
      >
        {platforms?.map((node) => (
          <MenuItem key={node?.dbId} value={node?.dbId}>
            {`${node?.shortName} - ${node?.pipedriveId}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BIDPlatform;
