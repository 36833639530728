import { Backdrop, CircularProgress } from '@mui/material';

type Props = {
  open: boolean;
};

const CustomBackdrop = ({ open }: Props) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CustomBackdrop;
