import {
  createTheme,
  PaletteOptions,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

enum PaletteEnum {
  light,
  dark,
}

const base: PaletteOptions = {
  primary: {
    main: '#FF564F',
  },
  secondary: {
    main: '#252F45',
  },
  error: {
    main: '#67282D',
  },
  warning: {
    main: '#D17823',
  },
  info: {
    main: '#3C92DD',
  },
  success: {
    main: '#3F5D66',
  },
};

export function palette(mode: PaletteEnum) {
  const light: PaletteOptions = {
    ...base,
    mode: 'light',
    text: {
      primary: '#3A4248',
    },
    background: {
      paper: '#F6ECE1',
      default: '#F6ECE1',
    },
  };

  const dark: PaletteOptions = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#F6ECE1',
    },
    background: {
      paper: '#2E2A27',
      default: '#2E2A27',
    },
  };

  return mode === PaletteEnum.light ? light : dark;
}

const theme = createTheme({
  palette: {
    ...palette(PaletteEnum.light),
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // borderColor: '#000000',
          },
        },
      },
    },
  },
});

type Props = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: Props) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
