import { useEffect } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';

import { POLL_NOTIFICATIONS_INTERVAL } from '../contants';
import { useNotifications } from '../contexts/NotificationsContext';
import { useStatusMessageQuery } from '../gql/generated/graphql';

const Notifications = () => {
  const { addNotification } = useNotifications();
  const { user } = useAuthenticator();
  const userName = user?.username;
  const clientId = user?.userId;

  const { data } = useStatusMessageQuery({
    variables: { clientId },
    pollInterval: POLL_NOTIFICATIONS_INTERVAL,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.statusMessage && data.statusMessage !== "{'message': 'No message received'}") {
      addNotification({
        message: data.statusMessage,
        timestamp: new Date(),
        read: false,
      });
    }
  }, [data]);

  return null;
};

export default Notifications;
