import { Link as RouterLink } from 'react-router-dom';

import { Badge, Link, ListItemButton, ListItemText } from '@mui/material';

import { useNotifications } from '../../contexts/NotificationsContext';

import styles from './styles';

const NavbarItemWithBadge = ({ path, location, text, Icon }) => {
  const { notifications } = useNotifications();

  const newNotificationsCount = notifications.filter((notification) => !notification.read).length;

  return (
    <Link component={RouterLink} to={`${path}${location}`} underline="none" color="inherit">
      <ListItemButton sx={styles.listItemButton}>
        <Badge sx={styles.badge} badgeContent={newNotificationsCount} color="error">
          <Icon sx={styles.navbarIcon} />
        </Badge>
        <ListItemText primary={text} />
      </ListItemButton>
    </Link>
  );
};

export default NavbarItemWithBadge;
