import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  box: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
};

export default styles;
