import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  mobileStepper: {
    flex: 1,
  },
  customPaper: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default styles;
