import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { DataGrid } from '@mui/x-data-grid';

import ProductCategorySelect from '../../components/ProductCategorySelect/ProductCategorySelect';
import { ProductCategory } from '../../components/ProductCategorySelect/types';
import {
  useCompaniesByCategoriesLazyQuery,
  useGetResearchSoftwareCategoriesLazyQuery,
} from '../../gql/generated/graphql';
import { useCompanyNavigation } from '../../hooks/useCompanyNavigation';
import { useProductCategoryData } from '../../hooks/useProductCategoryData';
import { getResearchCompaniesColumns } from '../../utils/getResearchCompaniesColumns';
import { ProductCategories } from '../ValidationPage/types';

const SoftwareCategoriesPage = () => {
  const { t } = useTranslation();
  const { handleValidate, handleNavigateToAssesment } = useCompanyNavigation();
  const {
    productCategory,
    setProductCategory,
    productCategoryListNodes,
    productSubCategoryListNodes,
    productSubCategory3ListNodes,
    productCategoryListLoading,
    productSubCategoryListLoading,
    productSubCategory3ListLoading,
    getProductSubCategoryList,
    getProductSubCategory3List,
  } = useProductCategoryData();
  const [
    getResearchSoftwareCategories,
    { data: researchSoftwareCategoriesData, loading: researchSoftwareCategoriesLoading },
  ] = useGetResearchSoftwareCategoriesLazyQuery();
  const [
    getCompaniesByCategories,
    { data: companiesByCategories, loading: companiesByCategoriesLoading },
  ] = useCompaniesByCategoriesLazyQuery();

  const researchSoftwareCategoriesNode =
    researchSoftwareCategoriesData?.getSoftwareCategories?.edges?.[0]?.node;

  const companiesColumns = getResearchCompaniesColumns(handleValidate, handleNavigateToAssesment);

  const handleProductCategoryChange = async (name: string, value: string | null) => {
    if (!value) return;

    const [category, categoryId] = value.includes('-') ? value.split('-') : [value, null];
    setProductCategory((prevState: ProductCategory) => ({
      ...prevState,
      [name]: category,
      categoryId: parseInt(categoryId) || null,
    }));
    if (name === ProductCategories.CATEGORY) {
      await getProductSubCategoryList({
        variables: { subCategory1: category },
        onCompleted: (data) => {
          const edges = data?.productCategoryList?.edges;

          if (
            !edges?.length ||
            (edges.length === 1 &&
              (edges[0].node.subCategory2 === '' || edges[0].node.subCategory2 === '-'))
          ) {
            getResearchSoftwareCategories({
              variables: {
                subCategory1: category,
              },
            });
            getCompaniesByCategories({ variables: { subCategory1: category } });
          }
        },
      });

      setProductCategory((prevState) => ({
        ...prevState,
        category: value,
        subCategory: null,
        subCategory3: null,
      }));
    }
    if (name === ProductCategories.SUB_CATEGORY) {
      await getProductSubCategory3List({
        variables: { subCategory2: category },
        onCompleted: (data) => {
          const edges = data?.productCategoryList?.edges;

          if (
            !edges?.length ||
            (edges.length === 1 &&
              (edges[0].node.subCategory3 === '' || edges[0].node.subCategory3 === '-'))
          ) {
            let variables = {
              subCategory1: productCategory.category.replace(/-\d+$/, ''),
              subCategory2: category,
            };
            getResearchSoftwareCategories({ variables });
            getCompaniesByCategories({ variables });
          }
        },
      });
      setProductCategory((prevState) => ({
        ...prevState,
        subCategory: value,
        subCategory3: null,
      }));
    }

    if (name === ProductCategories.SUB_CATEGORY3) {
      let variables = {
        subCategory1: productCategory.category.replace(/-\d+$/, ''),
        subCategory2: productCategory.subCategory.replace(/-\d+$/, ''),
        subCategory3: category,
      };
      getResearchSoftwareCategories({ variables });
      getCompaniesByCategories({ variables });
      setProductCategory((prevState) => ({
        ...prevState,
        subCategory3: value,
      }));
    }
  };

  const chartData = useMemo(() => {
    return researchSoftwareCategoriesNode?.googleTerm?.googleTrends.map((item) => ({
      ...item,
      date: new Date(item.date),
    }));
  }, [researchSoftwareCategoriesNode?.googleTerm?.googleTrends]);

  return (
    <Box gap={2} display="flex" flexDirection="column">
      <Typography variant="h6">{t('softwareCategoriesPage.title')}</Typography>
      <ProductCategorySelect
        categories={productCategoryListNodes}
        subCategories={productSubCategoryListNodes}
        subCategories3={productSubCategory3ListNodes}
        state={productCategory}
        loadingCategories={productCategoryListLoading}
        loadingSubCategories={productSubCategoryListLoading}
        loadingSubCategories3={productSubCategory3ListLoading}
        enableQuickSearch={false}
        enableSetCategoryButton={false}
        onChange={handleProductCategoryChange}
        onHandleSetCategory={() => {}}
      />
      {researchSoftwareCategoriesNode?.categoryDescription && (
        <>
          <Typography variant="subtitle1">
            {`${t('softwareCategoriesPage.categoryDescriptionText')}`}
          </Typography>
          <Typography variant="caption">
            {researchSoftwareCategoriesNode?.categoryDescription}
          </Typography>
        </>
      )}
      {chartData && (
        <Box width="100%" height="400px">
          <LineChart
            loading={researchSoftwareCategoriesLoading}
            yAxis={[{ label: t('softwareCategoriesPage.chartYAxis') }]}
            xAxis={[
              {
                dataKey: 'date',
                valueFormatter: (value) => new Date(value).toLocaleDateString('en-GB'),
                label: t('softwareCategoriesPage.chartXAxis'),
              },
            ]}
            series={[
              {
                dataKey: 'interest',
                label: t('softwareCategoriesPage.chartLegend'),
                showMark: false,
              },
            ]}
            dataset={chartData || []}
          />
        </Box>
      )}
      <DataGrid
        paginationMode="client"
        loading={companiesByCategoriesLoading}
        disableRowSelectionOnClick
        rows={companiesByCategories?.companiesByCategories || []}
        columns={companiesColumns}
        rowHeight={35}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
      />
    </Box>
  );
};

export default SoftwareCategoriesPage;
