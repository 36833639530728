import { useTranslation } from 'react-i18next';

import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';

import styles from './styles';

type Props<T> = {
  options: T[];
  loading: boolean;
  searchTerm: string;
  placeholder?: string;
  defaultValue?: boolean;
  onOptionSelect: (_: React.SyntheticEvent, value: T | null) => void;
  onInputChange: (_: React.SyntheticEvent, value: string, reason: string) => void;
  onReset: () => void;
  getCustomOptionLabel?: (option: T) => string;
};

const SearchComponent = <T,>({
  options,
  loading,
  searchTerm,
  placeholder,
  defaultValue = false,
  onInputChange,
  onOptionSelect,
  getCustomOptionLabel,
  onReset,
}: Props<T>) => {
  const { t } = useTranslation();

  const getOptionLabel = (option: T) => {
    if (getCustomOptionLabel) {
      return getCustomOptionLabel(option);
    }
    if ((option as any).node) {
      const { node } = option as any;
      return node.shortName !== '<unnamed reference customer>'
        ? `${node.shortName} - ${node.uniqueDomain}`
        : node.uniqueDomain;
    }
    return '';
  };

  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        freeSolo
        sx={styles.autocomplete}
        loading={loading}
        getOptionLabel={getOptionLabel}
        options={options}
        defaultValue={defaultValue ? options[0] : null}
        size="small"
        inputValue={searchTerm}
        onInputChange={onInputChange}
        onChange={onOptionSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder ? placeholder : t('domain')}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchComponent;
