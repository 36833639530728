import { useState } from 'react';

import { openAIService } from '../services/OpenAIService';

const useOpenAI = () => {
  const [response, setResponse] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const askOpenAI = async (prompt: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await openAIService(prompt);
      setResponse(response);
    } catch (error) {
      setError('Error while communicating with openAI. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return {
    response,
    loading,
    error,
    askOpenAI,
  };
};

export default useOpenAI;
