import { SxProps, Theme } from '@mui/system';

export const validateBenchmarkingExcelStyles: Record<string, SxProps<Theme>> = {
  validatedChip: {
    backgroundColor: 'secondary.main',
    color: 'white',
  },
  notValidatedChip: {
    backgroundColor: '#4C6390',
    color: 'white',
  },
};
