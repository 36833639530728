import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { IconButton, Tooltip } from '@mui/material';

import i18n from '../locales/i18n';

export const getResearchCompaniesColumns = (handleValidate, handleNavigateToAssesment) => [
  {
    field: 'shortName',
    headerName: i18n.t('researchPages.shortNameColumnField'),
    width: 200,
  },
  {
    field: 'uniqueDomain',
    headerName: i18n.t('researchPages.uniqueDomainColumnField'),
    width: 200,
  },
  {
    field: 'country',
    headerName: i18n.t('researchPages.countryColumnField'),
    width: 200,
  },
  {
    field: 'actions',
    headerName: '',
    width: 300,
    renderCell: (params) => (
      <>
        <IconButton size="small" onClick={() => handleValidate(params.row?.dbId)}>
          <Tooltip title={i18n.t('researchPages.validateColumnActionButton')}>
            <CheckCircleOutlineOutlinedIcon fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton size="small" onClick={() => handleNavigateToAssesment(params.row?.dbId)}>
          <Tooltip title={i18n.t('researchPages.viewColumnActionButton')}>
            <FactCheckIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    ),
  },
];
