import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface CompanyValidation {
  isSoftware: string | null;
}

interface ValidationContextType {
  companyValidation: CompanyValidation;
  setCompanyValidation: Dispatch<SetStateAction<CompanyValidation>>;
}

const companyValidationInitial: CompanyValidation = {
  isSoftware: null,
};

const ValidationContext = createContext({} as ValidationContextType);

export const ValidationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [companyValidation, setCompanyValidation] =
    useState<CompanyValidation>(companyValidationInitial);

  return (
    <ValidationContext.Provider value={{ companyValidation, setCompanyValidation }}>
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidation = (): ValidationContextType => {
  const context = useContext(ValidationContext);
  if (!context) {
    throw new Error('useValidation must be used within a ValidationProvider');
  }
  return context;
};
