import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  dialogCloseIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  customPaper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
};

export default styles;
