import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthenticator } from '@aws-amplify/ui-react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, CircularProgress, Divider, LinearProgress, Typography } from '@mui/material';

import { useDealAssessmentFileUrlLazyQuery } from '../../gql/generated/graphql';
import CustomPaper from '../DFMPage/components/CustomPaper/CustomPaper';

import UploadExcelFile from './components/UploadExcelFile';
import ValidateBenchmarkingExcel from './components/ValidateBenchmarkingExcel';
import { VisuallyHiddenInput } from './components/VisuallyHiddenInput';

import styles from './styles';

const InputFileUpload = ({ onProgress, onFileLoaded }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onProgress(0); // reset progress
    setIsLoading(true);

    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        onProgress(progress);
      }
    };

    reader.onload = (event) => {
      const fileContents = event.target?.result;
      onFileLoaded(fileContents); // call the callback with the file contents
      setIsLoading(false);
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
      setIsLoading(false);
    };
  };

  return (
    <Box display="flex" alignItems="center" gap={2} width="100%">
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        sx={styles.uploadButton}
      >
        {isLoading ? (
          <>
            {t('benchmarking.loadingIndicator')} <CircularProgress size={20} color="info" />
          </>
        ) : (
          <>
            {t('benchmarking.fileUpload')}
            <VisuallyHiddenInput type="file" accept=".xlsx" onChange={handleFileChange} />
          </>
        )}
      </Button>
      {/* <LinearProgress variant="determinate" value={0} sx={styles.linearProgress} /> */}
    </Box>
  );
};

const BenchmarkingOverview = () => {
  const { t } = useTranslation();
  const { user } = useAuthenticator();
  const [progress, setProgress] = useState(0);
  const [fileContents, setFileContents] = useState<ArrayBuffer | null>(null);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [companyID, setCompanyID] = useState<number | null>(null);

  const [getDealAssessmentFileUrl, { data: dealAssessmentFileUrl }] =
    useDealAssessmentFileUrlLazyQuery({});

  const dealAssessmentFileTriggered =
    dealAssessmentFileUrl?.getCompany?.edges?.[0]?.node?.dealAssessmentFileUrl?.generationTriggered;

  const handleValidationComplete = (success: boolean, companyID: number | null) => {
    setValidationSuccess(success);
    setCompanyID(companyID);
    console.log('Validation complete:', success, companyID);
  };

  return (
    <CustomPaper sx={styles.paper}>
      <Box display="flex" flexDirection="column" gap={2}>
        <div>
          <Typography variant="h6">{t('benchmarking.title')}</Typography>
        </div>
        <InputFileUpload onProgress={setProgress} onFileLoaded={setFileContents} />

        {fileContents && (
          <>
            <ValidateBenchmarkingExcel
              fileContents={fileContents}
              onValidationComplete={handleValidationComplete}
              onGenerationTriggered={getDealAssessmentFileUrl}
            />
            <Divider sx={styles.divider} />
          </>
        )}

        <Box display="flex" flexDirection="column" gap={2}>
          {validationSuccess && (
            <UploadExcelFile
              fileContents={fileContents}
              companyId={companyID}
              fileName="uploaded_file.xlsx"
              disableUpload={dealAssessmentFileTriggered}
              uploaderName={user?.username}
              onProgress={(progress) => console.log(`Progress: ${progress}%`)}
              onSheetCompletion={(sheetName) => console.log(`Sheet completed: ${sheetName}`)}
              onFileCompletion={() => console.log('File upload completed')}
            />
          )}
        </Box>
      </Box>
    </CustomPaper>
  );
};

export default BenchmarkingOverview;
