import { Link } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import { ensureProtocol } from '../../../../helpers/helpers';

import styles from './styles';

type Props = {
  to: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const CustomLink = ({ children, to, sx }: Props) => {
  const combinedSx: SxProps<Theme> = {
    ...styles.link,
    ...(sx as object),
  };
  return (
    <Link
      sx={combinedSx}
      color="secondary.main"
      target="_blank"
      rel="noopener noreferrer"
      href={ensureProtocol(to)}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
