import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  gridFooter: {
    border: 'none',
  },
  dataGridMargin: {
    mt: 2,
    mb: 4,
  },
};

export default styles;
