import axios from 'axios';

import { OpenAICompletionResponse } from './types';

export const openAIService = async (prompt: string): Promise<string> => {
  const apiKey = process.env.REACT_APP_OPEN_AI_API_KEY;

  if (!apiKey) {
    throw new Error('API key is not defined');
  }

  const response = await axios.post<OpenAICompletionResponse>(
    'https://api.openai.com/v1/chat/completions',
    {
      model: 'gpt-4o-mini',
      messages: [
        {
          role: 'system',
          content: 'You are an expert in industry classification codes, specifically SIC codes.',
        },
        {
          role: 'user',
          content: `Please provide only the 4-digit SIC code for the following industry: ${prompt}. Do not include any additional text or explanations but only the code.`,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    },
  );

  return response.data?.choices[0]?.message?.content;
};
