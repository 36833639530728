import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, CircularProgress, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDebouncedCallback } from 'use-debounce';

import {
  SoftwareCategoryEdge,
  useGetSoftwareCategoriesLazyQuery,
} from '../../gql/generated/graphql';
import QuickSearch from '../../pages/ValidationPage/components/QuickSearch/QuickSearch';

import { ProductCategory } from './types';

const GRID_DROPDOWN_WIDTH_SMALL = 3;
const GRID_DROPDOWN_WIDTH_LARGE = 4;

type Props = {
  // TODO: set proper type after backend for quering that data will be ready
  categories: any;
  subCategories: any;
  subCategories3: any;
  state: ProductCategory;
  loadingCategories: boolean;
  loadingSubCategories: boolean;
  loadingSubCategories3: boolean;
  enableQuickSearch?: boolean;
  enableSetCategoryButton?: boolean;
  onHandleSetCategory: () => void;
  onChange: (type: string, value: string | null) => void;
  onSetProductCategoryId?: (categoryId: number) => void;
};

const ProductCategorySelect = ({
  categories,
  subCategories,
  subCategories3,
  state,
  loadingCategories,
  loadingSubCategories,
  loadingSubCategories3,
  enableQuickSearch = true,
  enableSetCategoryButton = true,
  onHandleSetCategory,
  onChange,
  onSetProductCategoryId,
}: Props) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState<SoftwareCategoryEdge[]>([]);
  const [isSelected, setIsSelected] = useState(false);

  const [search, { loading }] = useGetSoftwareCategoriesLazyQuery({
    onCompleted: (data) => {
      setOptions(data?.getSoftwareCategories?.edges);
    },
  });

  useEffect(() => {
    search();
  }, []);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    search({ variables: { name: value } });
  }, 500);

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  const handleInputChange = (_, value: string, reason: string) => {
    if (reason === 'clear') {
      handleReset();
      setIsSelected(false);
      search();
      return;
    }

    if (value === '') {
      setIsSelected(false);
    }

    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    }
  };
  const handleReset = () => {
    setSearchTerm('');
    setOptions([]);
  };

  const handleOptionSelect = (
    _: React.SyntheticEvent,
    selectedOption: SoftwareCategoryEdge | null,
  ) => {
    if (!selectedOption) return;

    setIsSelected(true);
    const categoryId = selectedOption.node.dbId;
    onSetProductCategoryId(categoryId);
  };

  const handleSetQuickSearchCategory = () => {
    onHandleSetCategory();
    handleReset();
    setIsSelected(false);
    search();
  };

  const gridDropdownWidth = enableSetCategoryButton
    ? GRID_DROPDOWN_WIDTH_SMALL
    : GRID_DROPDOWN_WIDTH_LARGE;

  return (
    <Grid mt={2} container spacing={2} alignItems="center">
      <Grid item xs={12} md={gridDropdownWidth}>
        <FormControl fullWidth size="small">
          <InputLabel id="category">{t('validation.categorySelectLabel')}</InputLabel>
          <Select
            name="category"
            labelId="select_category"
            id="select_category"
            value={state?.category}
            label={t('validation.categorySelectLabel')}
            size="small"
            onChange={handleChange}
            disabled={loadingCategories}
          >
            {loadingCategories ? (
              <MenuItem disabled>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                  <Box ml={2}>{t('validation.productCategoriesLoading')}</Box>
                </Box>
              </MenuItem>
            ) : (
              categories?.map((item) => (
                <MenuItem key={item?.id} value={`${item?.subCategory1}-${item?.dbId}`}>
                  {item?.subCategory1}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={gridDropdownWidth}>
        <FormControl fullWidth size="small">
          <InputLabel id="subCategory">{t('validation.subCategorySelectLabel')}</InputLabel>
          <Select
            name="subCategory"
            labelId="select_sub_category"
            id="select_sub_category"
            value={state?.subCategory}
            label={t('validation.subCategorySelectLabel')}
            size="small"
            onChange={handleChange}
            disabled={!state.category}
          >
            {loadingSubCategories ? (
              <MenuItem disabled>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                  <Box ml={2}>{t('validation.productCategoriesLoading')}</Box>
                </Box>
              </MenuItem>
            ) : (
              subCategories?.map((item) => (
                <MenuItem key={item?.id} value={`${item?.subCategory2}-${item?.dbId}`}>
                  {item?.subCategory2}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={gridDropdownWidth}>
        <FormControl fullWidth size="small">
          <InputLabel id="subCategory3">{t('validation.subCategory3SelectLabel')}</InputLabel>
          <Select
            name="subCategory3"
            labelId="select_sub_category3"
            id="select_sub_category3"
            value={state?.subCategory3}
            label={t('validation.subCategory3SelectLabel')}
            size="small"
            onChange={handleChange}
            disabled={!state.subCategory}
          >
            {loadingSubCategories3 ? (
              <MenuItem disabled>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                  <Box ml={2}>{t('validation.productCategoriesLoading')}</Box>
                </Box>
              </MenuItem>
            ) : subCategories3?.length === 0 ? (
              <MenuItem disabled>{t('validation.productCategoryEmptyList')}</MenuItem>
            ) : (
              subCategories3?.map((item) => (
                <MenuItem key={item?.id} value={`${item?.subCategory3}-${item?.dbId}`}>
                  {item?.subCategory3}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      {enableSetCategoryButton && (
        <Grid item xs={3}>
          <Button
            disabled={!state.category}
            fullWidth
            variant="contained"
            color="primary"
            onClick={onHandleSetCategory}
          >
            {t('validation.formCategorySetCategoryButton')}
          </Button>
        </Grid>
      )}
      {enableQuickSearch && (
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <Box display="flex" gap={2}>
              <QuickSearch
                options={options}
                loading={loading}
                searchTerm={searchTerm}
                onInputChange={handleInputChange}
                onOptionSelect={handleOptionSelect}
              />
              <Button
                disabled={!isSelected}
                variant="contained"
                color="primary"
                onClick={handleSetQuickSearchCategory}
              >
                {t('validation.formCategorySetCategoryButton')}
              </Button>
            </Box>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default ProductCategorySelect;
