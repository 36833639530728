import { Paper } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import styles from './styles';
type Props = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const CustomPaper = ({ children, sx }: Props) => {
  const combinedSx: SxProps<Theme> = {
    ...styles.paper,
    ...(sx as object),
  };
  return <Paper sx={combinedSx}>{children}</Paper>;
};

export default CustomPaper;
