import { useEffect } from 'react';

import { List, ListItem, ListItemText } from '@mui/material';

import { useNotifications } from '../../contexts/NotificationsContext';

const NotificationsPage = () => {
  const { notifications, markAllAsRead } = useNotifications();

  useEffect(() => {
    markAllAsRead();
  }, []);

  const sortedNotifications = [...notifications].sort(
    (a, b) => b.timestamp.getTime() - a.timestamp.getTime(),
  );

  return (
    <List>
      {sortedNotifications.map((notification, index) => (
        <ListItem key={index + notification.message + notification.timestamp.toLocaleString()}>
          <ListItemText
            primary={notification.message}
            secondary={notification.timestamp.toLocaleString()}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default NotificationsPage;
