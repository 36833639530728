import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material';
import { Chip, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import CustomBackdrop from '../../components/CustomBackdrop/CustomBackdrop';
import { FLAG_URL } from '../../contants';
import { useSearchCompanyLazyQuery, useStartCrawlMutation } from '../../gql/generated/graphql';
import { useCompanyNavigation } from '../../hooks/useCompanyNavigation';
import usePagination from '../../hooks/usePagination';
import { paths } from '../../routes/paths';
import CustomPaper from '../DFMPage/components/CustomPaper/CustomPaper';

import styles from './styles';

const SearchPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleValidate, handleNavigateToAssesment } = useCompanyNavigation();
  const [searchTerm, setSearchTerm] = useState('');
  const [crawlCompany, setCrawlCompany] = useState('');

  const [getCompanies, { data: companiesData, loading: getCompaniesLoading, fetchMore }] =
    useSearchCompanyLazyQuery();

  const [startCrawl, { loading: startCrawlLoading }] = useStartCrawlMutation();

  const { paginationModel, isLoading, handlePaginationChange } = usePagination({
    fetchMore,
    pageInfo: companiesData?.cortexFirms?.pageInfo,
  });

  const debouncedSearch = useDebouncedCallback((value: string) => {
    getCompanies({ variables: { searchTerm: value } });
  }, 500);

  const companiesNodes = companiesData?.cortexFirms?.edges?.map((node) => node?.node);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value);
    if (event.target.value) {
      debouncedSearch(event.target.value);
    }
  };

  const searchColumnCategories = [
    {
      field: 'shortName',
      headerName: t('searchPage.shortNameColumnField'),
      width: 200,
    },
    {
      field: 'uniqueDomain',
      headerName: t('searchPage.uniqueDomainColumnField'),
      width: 200,
    },
    {
      field: 'country',
      headerName: t('searchPage.countryColumnField'),
      width: 100,
      renderCell: (params) => {
        return params?.formattedValue ? (
          <img
            src={FLAG_URL(params?.formattedValue)}
            width={32}
            height={32}
            alt="Company country flag"
          />
        ) : (
          ''
        );
      },
    },
    {
      field: 'validated',
      headerName: t('searchPage.validatedColumnField'),
      renderCell: (params) => {
        return (
          <Chip
            sx={params?.formattedValue ? styles.validatedChip : styles.notValidatedChip}
            label={
              params?.formattedValue
                ? t('searchPage.validatedChipLabel')
                : t('searchPage.notValidatedChipLabel')
            }
            size="small"
          />
        );
      },
      width: 150,
    },
    {
      field: 'isSoftwareCompany',
      headerName: t('searchPage.isSoftwareFirmColumnField'),
      renderCell: (params) => {
        return params?.row?.isSoftwareCompany ? <DoneIcon /> : <CloseIcon />;
      },
      width: 150,
    },
    {
      field: 'doNotCrawl',
      headerName: t('searchPage.doNotCrawlColumnField'),
      renderCell: (params) => {
        const doNotCrawl = params?.formattedValue?.doNotCrawl;
        return doNotCrawl ? <CloseIcon /> : null;
      },
      width: 150,
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      width: 350,
      renderCell: (params) => {
        const isValidated = params?.row?.validated;
        const doNotCrawl = params?.formattedValue?.doNotCrawl;
        return (
          <>
            <IconButton
              disabled={isValidated}
              size="small"
              onClick={() => handleValidate(params.row?.dbId)}
            >
              <Chip
                sx={isValidated ? styles.validatedChipButton : styles.notValidatedChipButton}
                label={t('searchPage.validateButton')}
                size="small"
              />
            </IconButton>
            <IconButton
              disabled={!isValidated}
              size="small"
              onClick={() => handleNavigateToAssesment(params.row?.dbId)}
            >
              <Chip
                sx={isValidated ? styles.notValidatedChipButton : styles.validatedChipButton}
                label={t('searchPage.evaluateButton')}
                size="small"
              />
            </IconButton>
            {/* TODO: add revalidate function! */}
            <IconButton disabled={!isValidated} size="small" onClick={() => {}}>
              <Chip
                sx={isValidated ? styles.notValidatedChipButton : styles.validatedChipButton}
                label={t('searchPage.revalidateButton')}
                size="small"
              />
            </IconButton>
            <IconButton
              disabled={doNotCrawl}
              size="small"
              onClick={() => handleForceCrawl(params.row)}
            >
              <Chip
                sx={!doNotCrawl ? styles.notValidatedChipButton : styles.validatedChipButton}
                label={t('searchPage.forceCrawlButton')}
                size="small"
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleReset = () => {
    setSearchTerm('');
    const event = { target: { value: '' } } as ChangeEvent<HTMLInputElement>;
    handleInputChange(event);
  };

  const handleForceCrawl = async (row) => {
    await startCrawl({
      variables: {
        companyName: row?.shortName,
        domain: row?.uniqueDomain,
        forceSw: true,
      },
    });
  };

  const handleForceCrawlByCompanyName = async () => {
    if (!crawlCompany) return;
    const result = await startCrawl({
      variables: {
        domain: crawlCompany,
        forceDeepAnalysis: true,
      },
    });

    if (result?.data?.startCrawler?.success) {
      setCrawlCompany('');
    }
  };

  return (
    <Box display="flex" gap={4} flexDirection="column">
      <TextField
        label={t('searchPage.searchbarPlaceholder')}
        variant="outlined"
        fullWidth
        onChange={handleInputChange}
        value={searchTerm}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleReset} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CustomPaper>
        <DataGrid
          paginationMode="server"
          loading={getCompaniesLoading || isLoading}
          getRowId={(row) => row?.dbId}
          disableRowSelectionOnClick
          rows={companiesNodes || []}
          columns={searchColumnCategories}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          rowHeight={35}
          initialState={{
            pagination: {
              rowCount: -1,
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
      </CustomPaper>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <TextField
            fullWidth
            size="small"
            placeholder={t('searchPage.crawlTextFieldPlaceholder')}
            value={crawlCompany}
            onChange={(e) => setCrawlCompany(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleForceCrawlByCompanyName}
          >
            {t('searchPage.forceCrawlButton')}
          </Button>
        </Grid>
      </Grid>
      <CustomBackdrop open={startCrawlLoading} />
    </Box>
  );
};

export default SearchPage;
