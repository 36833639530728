import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  listItemButton: {
    gap: 1,
  },
  navbarIcon: {
    fontSize: 15,
  },
  badge: {
    '& .MuiBadge-badge': {
      fontSize: 9,
      height: 15,
      width: 15,
      minWidth: 15,
      minHeight: 15,
    },
  },
};

export default styles;
