import { useTranslation } from 'react-i18next';

import { Box, Button, CircularProgress, Grid, InputAdornment, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { validateTechnologyStyles } from '../ValidationSteps/styles';
import { TechnologyType } from '../ValidationSteps/ValidateTechnology';

type Props = {
  // TODO: set proper type after backend for quering that data will be ready
  categories: any;
  technologies: any;
  technReferenceUrl: string;
  state: TechnologyType;
  technologiesLoading: boolean;
  categoriesLoading: boolean;
  onChangeTechnology: (type: string, value: string | null) => void;
  onChangeTechReferenceUrl: (url: string) => void;
  onHandleAddTechnology: () => void;
};

const TechnologySelect = ({
  categories,
  technologies,
  technReferenceUrl,
  state,
  technologiesLoading,
  categoriesLoading,
  onChangeTechnology,
  onChangeTechReferenceUrl,
  onHandleAddTechnology,
}: Props) => {
  const { t } = useTranslation();

  const handleChangeTechnology = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    onChangeTechnology(name, value);
  };

  return (
    <Grid mt={2} container spacing={2} alignItems="center">
      <Grid item xs={12} md={4}>
        <FormControl fullWidth size="small">
          <InputLabel id="category">
            {t('validation.validateTechnology.categorySelectLabel')}
          </InputLabel>
          <Select
            name="category"
            labelId="select_category"
            id="select_category"
            value={state?.category}
            label={t('validation.validateTechnology.categorySelectLabel')}
            size="small"
            onChange={handleChangeTechnology}
          >
            {categoriesLoading ? (
              <MenuItem disabled>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                  <Box ml={2}>{t('validation.validateTechnology.validateTechnology')}</Box>
                </Box>
              </MenuItem>
            ) : (
              categories?.map((item) => (
                <MenuItem key={item?.dbId} value={item?.breadcrumb}>
                  {item?.breadcrumb}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth size="small">
          <InputLabel id="technology">
            {t('validation.validateTechnology.technologySelectLabel')}
          </InputLabel>
          <Select
            disabled={!state.category}
            name="technology"
            labelId="technology"
            id="technology"
            value={state?.technology}
            label={t('validation.validateTechnology.technologySelectLabel')}
            size="small"
            onChange={handleChangeTechnology}
          >
            {technologiesLoading ? (
              <MenuItem disabled>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                  <Box ml={2}>{t('validation.validateTechnology.validateTechnology')}</Box>
                </Box>
              </MenuItem>
            ) : (
              technologies?.map((item) => (
                <MenuItem key={item?.dbId} value={item?.name}>
                  {item?.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Button variant="contained" color="primary" onClick={onHandleAddTechnology}>
          {t('validation.validateTechnology.addTechnologyButton')}
        </Button>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          sx={validateTechnologyStyles.textField}
          label={t('validation.validateTechnology.techReferenceTextfieldLabel')}
          id="mentioned_at_url"
          fullWidth
          value={technReferenceUrl}
          onChange={(e) => onChangeTechReferenceUrl(e.target.value)}
          size="small"
          disabled
          InputProps={{
            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {/* TODO: Missing mutation to add tech reference. For now we are skipping it in development. */}
        <Button variant="contained" color="primary" onClick={() => {}} disabled>
          {t('validation.validateTechnology.addTechReferenceButton')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TechnologySelect;
