import { useState } from 'react';

import * as Sentry from '@sentry/react';

const PAGE_SIZE = 10;

const usePagination = ({ fetchMore, pageInfo, otherVariables = {} }) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handlePaginationChange = (newPaginationModel) => {
    if (paginationModel.page < 0) return;
    setIsLoading(true);

    let variables = {};
    if (paginationModel.page < newPaginationModel.page) {
      variables = {
        after: pageInfo?.endId,
      };
    } else {
      variables = {
        before: pageInfo?.startId,
      };
    }

    fetchMore({
      variables: {
        first: paginationModel.pageSize,
        ...variables,
        ...otherVariables,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return fetchMoreResult;
      },
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setIsLoading(false);
        console.log('Error while loading more: ', error);
      });

    setPaginationModel(newPaginationModel);
  };

  return {
    paginationModel,
    isLoading,
    handlePaginationChange,
    setPaginationModel,
  };
};

export default usePagination;
