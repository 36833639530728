import { Box, Chip } from '@mui/material';

import styles from './styles';

type Keyword = {
  id: string;
  keyword: string;
};

type Props = {
  tags: Keyword[];
};

const TagsBox = ({ tags }: Props) => {
  return (
    <Box gap={0.5} sx={styles.box}>
      {tags?.map((tag, index) => <Chip key={tag.keyword + tag.id + index} label={tag.keyword} />)}
    </Box>
  );
};

export default TagsBox;
