import { useTranslation } from 'react-i18next';

import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, TextField } from '@mui/material';

type Props = {
  searchTerm: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleReset: () => void;
};

const Search = ({ searchTerm, handleInputChange, handleReset }: Props) => {
  const { t } = useTranslation();
  return (
    <TextField
      label={t('searchPage.searchbarPlaceholder')}
      variant="outlined"
      fullWidth
      onChange={handleInputChange}
      value={searchTerm}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleReset} edge="end">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
