import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { useTreeViewApiRef } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import { GetAllSicCodesQuery } from '../../gql/generated/graphql';

type TreeNode = {
  children: { [key: string]: TreeNode };
  description: string | null;
  sicCodeStr?: string;
  exists: boolean;
  isLeaf: boolean;
};

type Props = {
  data: GetAllSicCodesQuery;
  loading: boolean;
  selectedSicCodes: string[];
  sicCodeOpenAI?: string;
  onCheckboxChange: Dispatch<SetStateAction<string[]>>;
};

const buildTree = (
  data: GetAllSicCodesQuery['getAllSicCodes']['edges'],
): { [key: string]: TreeNode } => {
  const tree: { [key: string]: TreeNode } = {};
  const existingCodes = new Set(data.map(({ node }) => node.sicCodeStr));

  const getPrefixes = (code: string): string[] => {
    const prefixes: string[] = [];
    for (let i = 1; i <= code.length; i++) {
      prefixes.push(code.substring(0, i));
    }
    return prefixes;
  };

  data.forEach(({ node }) => {
    const { sicCodeStr } = node;
    const prefixes = getPrefixes(sicCodeStr);

    let currentLevel = tree;
    prefixes.forEach((prefix) => {
      if (!currentLevel[prefix]) {
        currentLevel[prefix] = {
          children: {},
          description: null,
          sicCodeStr: prefix,
          exists: existingCodes.has(prefix),
          isLeaf: false,
        };
      }
      currentLevel = currentLevel[prefix].children;
    });
  });

  data.forEach(({ node }) => {
    const { sicCodeStr, description } = node;
    let currentLevel = tree;

    const segments = getPrefixes(sicCodeStr);
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (segment === sicCodeStr && description) {
        currentLevel[segment].description = `${sicCodeStr} - ${description}`;
        currentLevel[segment].isLeaf = Object.keys(currentLevel[segment].children).length === 0;
      }
      currentLevel = currentLevel[segment].children;
    }
  });

  return tree;
};

const SicCodesTree = ({
  data,
  loading,
  selectedSicCodes,
  sicCodeOpenAI,
  onCheckboxChange,
}: Props) => {
  const { t } = useTranslation();
  const apiRef = useTreeViewApiRef();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  useEffect(() => {
    if (sicCodeOpenAI && apiRef.current) {
      const newExpandedItems: string[] = [];
      let code = '';

      for (let i = 1; i <= sicCodeOpenAI.length; i++) {
        code = sicCodeOpenAI.substring(0, i);
        newExpandedItems.push(code);
      }

      newExpandedItems.forEach((itemId) => {
        // @ts-ignore
        apiRef.current.setItemExpansion({} as React.SyntheticEvent, itemId, true);
      });

      setExpandedItems(newExpandedItems);

      if (!selectedSicCodes.includes(sicCodeOpenAI)) {
        onCheckboxChange((prev) => [...prev, sicCodeOpenAI]);
      }
    }
  }, [sicCodeOpenAI, apiRef, selectedSicCodes, onCheckboxChange]);

  const handleCheckboxChange = (code: string) => {
    onCheckboxChange((prev) => {
      if (prev.includes(code)) {
        return prev.filter((c) => c !== code);
      }
      return [...prev, code];
    });
  };

  const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  const renderTree = useCallback(
    (node: TreeNode, key: string) => {
      const isFirstLevel = key.length === 1;
      const hasDescription = node.description !== null && node.exists;

      let label;
      if (node.isLeaf) {
        label = (
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedSicCodes.includes(node.sicCodeStr || '')}
                onChange={() => handleCheckboxChange(node.sicCodeStr || '')}
              />
            }
            label={hasDescription && !isFirstLevel ? node.description : key}
          />
        );
      } else {
        label = hasDescription && !isFirstLevel ? node.description : key;
      }

      return (
        <TreeItem key={key} itemId={key} label={label}>
          {Object.keys(node.children).map((childKey) =>
            renderTree(node.children[childKey], childKey),
          )}
        </TreeItem>
      );
    },
    [selectedSicCodes, handleCheckboxChange],
  );

  if (loading)
    return (
      <Box textAlign="center" display="flex" justifyContent="center" alignItems="center" pt={14}>
        <CircularProgress size="small" />
      </Box>
    );

  const treeData = buildTree(data?.getAllSicCodes?.edges);

  return (
    <Box>
      <Typography variant="subtitle2">{t('customerIndustriesPage.sicCodesTree')}</Typography>
      <SimpleTreeView
        apiRef={apiRef}
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
      >
        {Object.keys(treeData).map((key) => renderTree(treeData[key], key))}
      </SimpleTreeView>
    </Box>
  );
};

export default SicCodesTree;
