import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  box: {
    height: 600,
    width: '100%',
  },
  chipButton: {
    backgroundColor: 'white',
    borderRadius: 1,
    color: 'primary.main',
  },
};

export default styles;
