import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import {
  useCortexFeedbackPipedriveBridgeMutation,
  useCreatePdOrganizationMutation,
  useGetBuyAndBuildPlatformsQuery,
  usePrecalculateAllKpisMutation,
} from '../../../../gql/generated/graphql';
import { paths } from '../../../../routes/paths';
import BIDPlatform from '../BIDPlatform/BIDPlatform';

import { finishStepStyles } from './styles';

// TODO: get from query when backend will be ready
const selectOptions = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
];

type Props = {
  companyId: number;
};

const FinishStep = ({ companyId }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [platform, setPlatform] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    newPlatform: false,
    addOnExisting: false,
    addOnFuture: false,
    saas: false,
    venture: false,
    none: false,
    partOfGroup: false,
    otherOwnership: false,
    sellsHardware: false,
    tooMuchConsulting: false,
    reseller: false,
    wrongBusinessModel: false,
    b2c: false,
    wrongGeography: false,
    tooBig: false,
    tooSmall: false,
    createOrganization: false,
    createDeal: false,
    selectValue: '',
    note: '',
  });

  const { user } = useAuthenticator();

  var isNoteEnabled = formData.createOrganization || formData.createDeal; // Also enable the note field, if the company has a pipedrive ID

  const { data: getBuyAndBuildPlatformsData } = useGetBuyAndBuildPlatformsQuery({
    variables: { sortForTargetId: companyId },
  });

  const buyAndBuildPlatformsNodes =
    getBuyAndBuildPlatformsData?.getBuyAndBuildPlatforms?.edges?.map((edge) => edge?.node);

  const [createPDOrganization, { loading: createPDOrganizationLoading }] =
    useCreatePdOrganizationMutation();

  const [precalculateAllKpis, { loading: precalculateAllKpisLoading }] =
    usePrecalculateAllKpisMutation();

  const [addBidPlatform, { loading: addBidPlatformLoading }] =
    useCortexFeedbackPipedriveBridgeMutation();

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedFormData = {
      ...formData,
      [event.target.name]: event.target.checked,
    };

    // Enable the 'note' field if either 'createOrganization' or 'createDeal' is switched on
    isNoteEnabled = updatedFormData.createOrganization || updatedFormData.createDeal;
    // TODO: Also enable the note field if the compoany has a pipedrive ID

    setFormData({
      ...updatedFormData,
      note: isNoteEnabled ? formData.note : '',
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormData({
      ...formData,
      selectValue: event.target.value,
    });
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      note: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      const authId = user?.userId;

      const variables = {
        companyId,
        authId,
        ...formData,
      };

      createPDOrganization({ variables });
      precalculateAllKpis({ variables: { companyId } });

      if (platform) {
        addBidPlatform({ variables: { addOnForBidId: parseInt(platform), companyId } });
      }

      navigate(paths.crawlerInbox);
    } catch (error) {
      console.error('Error fetching user email or submitting data:', error);
      Sentry.captureException(error);
    }
  };

  const handleChangePlatform = (event: SelectChangeEvent) => {
    console.log('platform', event.target.value);
    setPlatform(event.target.value);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={finishStepStyles.paperPadding}>
            <Typography variant="h6">{t('validation.finishStep.relevantHeader')}</Typography>
            <Divider sx={finishStepStyles.divider} />
            <Typography>{t('validation.finishStep.whyText')}</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="newPlatform"
                    checked={formData.newPlatform}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.newPlatformSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="addOnExisting"
                    checked={formData.addOnExisting}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.addOnExistingSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="addOnFuture"
                    checked={formData.addOnFuture}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.addOnFutureSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="saas"
                    checked={formData.saas}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.saasSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="venture"
                    checked={formData.venture}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.ventureSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="none"
                    checked={formData.none}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.noneSwitch')}
              />
            </FormGroup>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={finishStepStyles.paperPadding}>
            <Typography variant="h6">{t('validation.finishStep.irreleveantHeader')}</Typography>
            <Divider sx={finishStepStyles.divider} />
            <Typography>{t('validation.finishStep.whyText')}</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="partOfGroup"
                    checked={formData.partOfGroup}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.partOfGroupSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="otherOwnership"
                    checked={formData.otherOwnership}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.otherOwnershipSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="sellsHardware"
                    checked={formData.sellsHardware}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.sellsHardwareSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="tooMuchConsulting"
                    checked={formData.tooMuchConsulting}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.tooMuchConsultingSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="reseller"
                    checked={formData.reseller}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.resellerSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="wrongBusinessModel"
                    checked={formData.wrongBusinessModel}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.wrongBusinessModelSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="b2c"
                    checked={formData.b2c}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.b2cSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="wrongGeography"
                    checked={formData.wrongGeography}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.wrongGeographySwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="tooBig"
                    checked={formData.tooBig}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.tooBigSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="tooSmall"
                    checked={formData.tooSmall}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.tooSmallSwitch')}
              />
            </FormGroup>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={finishStepStyles.paperPadding}>
            <Typography variant="h6">{t('validation.finishStep.pipedriveHeader')}</Typography>
            <Divider sx={finishStepStyles.divider} />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="createOrganization"
                    checked={formData.createOrganization}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.createOrganizationSwitch')}
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="createDeal"
                    checked={formData.createDeal}
                    onChange={handleSwitchChange}
                  />
                }
                label={t('validation.finishStep.createDealSwitch')}
              />
              <Box mt={2}>
                <Grid container spacing={4}>
                  <Grid item md={4} xs={12} gap={1} display="flex" flexDirection="column">
                    <Typography>{t('validation.finishStep.addOnLabel')}</Typography>
                    <BIDPlatform
                      platform={platform}
                      platforms={buyAndBuildPlatformsNodes}
                      onHandleChangePlatform={handleChangePlatform}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography>{t('validation.finishStep.pipedriveNote')}</Typography>
                    <TextField
                      disabled={!isNoteEnabled}
                      name="note"
                      placeholder={t('validation.finishStep.pipedriveNotePlaceholder')}
                      multiline
                      rows={5}
                      maxRows={5}
                      fullWidth
                      value={formData.note}
                      onChange={handleNoteChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </FormGroup>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center">
            <Button variant="contained" onClick={handleSubmit}>
              {t('validation.finishStep.handleSubmitButton')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CustomBackdrop open={createPDOrganizationLoading || precalculateAllKpisLoading} />
    </Box>
  );
};

export default FinishStep;
