import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import ProductCategorySelect from '../../components/ProductCategorySelect/ProductCategorySelect';
import { ProductCategory } from '../../components/ProductCategorySelect/types';
import SicCodeOpenAi from '../../components/SicCodeOpenAi/SicCodeOpenAi';
import SicCodesTree from '../../components/SicCodesTree/SicCodesTree';
import {
  useCompaniesByIndustryAndCategoryLazyQuery,
  useGetAllSicCodesQuery,
} from '../../gql/generated/graphql';
import { useCompanyNavigation } from '../../hooks/useCompanyNavigation';
import { useProductCategoryData } from '../../hooks/useProductCategoryData';
import { getResearchCompaniesColumns } from '../../utils/getResearchCompaniesColumns';
import { ProductCategories } from '../ValidationPage/types';

const MatrixSearchPage = () => {
  const { t } = useTranslation();
  const { handleValidate, handleNavigateToAssesment } = useCompanyNavigation();
  const [selectedSicCodes, setSelectedSicCodes] = useState<string[]>([]);
  const [openAISicCode, setOpenAISicCode] = useState<string>('');
  const {
    productCategory,
    productCategoryListNodes,
    productSubCategoryListNodes,
    productSubCategory3ListNodes,
    productCategoryListLoading,
    productSubCategoryListLoading,
    productSubCategory3ListLoading,
    setProductCategory,
    getProductSubCategoryList,
    getProductSubCategory3List,
  } = useProductCategoryData();

  const { data: sicCodes, loading } = useGetAllSicCodesQuery();

  const [
    getCompaniesByIndustryAndCategory,
    { data: companiesByIndustryAndCategory, loading: companiesByIndustryAndCategoryLoading },
  ] = useCompaniesByIndustryAndCategoryLazyQuery();

  useEffect(() => {
    if (selectedSicCodes.length > 0) {
      let variables: {
        sicCodes: string[];
        subCategory1?: string;
        subCategory2?: string;
        subCategory3?: string;
      } = {
        sicCodes: selectedSicCodes,
      };

      if (productCategory.category) {
        variables.subCategory1 = productCategory.category.replace(/-\d+$/, '');
      }

      if (productCategory.subCategory) {
        variables.subCategory2 = productCategory.subCategory.replace(/-\d+$/, '');
      }

      if (productCategory.subCategory3) {
        variables.subCategory3 = productCategory.subCategory3.replace(/-\d+$/, '');
      }
      getCompaniesByIndustryAndCategory({
        variables,
      });
    }
  }, [selectedSicCodes]);

  const companiesColumns = getResearchCompaniesColumns(handleValidate, handleNavigateToAssesment);

  const handleProductCategoryChange = async (name: string, value: string | null) => {
    if (!value) return;

    const [category, categoryId] = value.includes('-') ? value.split('-') : [value, null];
    setProductCategory((prevState: ProductCategory) => ({
      ...prevState,
      [name]: category,
      categoryId: parseInt(categoryId) || null,
    }));
    if (name === ProductCategories.CATEGORY) {
      await getProductSubCategoryList({
        variables: { subCategory1: category },
        onCompleted: (data) => {
          const edges = data?.productCategoryList?.edges;

          if (
            !edges?.length ||
            (edges.length === 1 &&
              (edges[0].node.subCategory2 === '' || edges[0].node.subCategory2 === '-'))
          ) {
            getCompaniesByIndustryAndCategory({
              variables: {
                sicCodes: selectedSicCodes,
                subCategory1: category,
              },
            });
          }
        },
      });

      setProductCategory((prevState) => ({
        ...prevState,
        category: value,
        subCategory: null,
        subCategory3: null,
      }));
    }
    if (name === ProductCategories.SUB_CATEGORY) {
      await getProductSubCategory3List({
        variables: { subCategory2: category },
        onCompleted: (data) => {
          const edges = data?.productCategoryList?.edges;

          if (
            !edges?.length ||
            (edges.length === 1 &&
              (edges[0].node.subCategory3 === '' || edges[0].node.subCategory3 === '-'))
          ) {
            let variables = {
              subCategory1: productCategory.category.replace(/-\d+$/, ''),
              subCategory2: category,
              sicCodes: selectedSicCodes,
            };
            getCompaniesByIndustryAndCategory({
              variables,
            });
          }
        },
      });
      setProductCategory((prevState) => ({
        ...prevState,
        subCategory: value,
        subCategory3: null,
      }));
    }
    if (name === ProductCategories.SUB_CATEGORY3) {
      let variables = {
        subCategory1: productCategory.category.replace(/-\d+$/, ''),
        subCategory2: productCategory.subCategory.replace(/-\d+$/, ''),
        subCategory3: category,
        sicCodes: selectedSicCodes,
      };
      getCompaniesByIndustryAndCategory({
        variables,
      });
      setProductCategory((prevState) => ({
        ...prevState,
        subCategory3: value,
      }));
    }
  };

  const handleSetOpenAISicCode = (code: string) => {
    setOpenAISicCode(code);
  };

  return (
    <Box gap={2} display="flex" flexDirection="column">
      <Typography variant="h6">{t('matrixSearchPage.title')}</Typography>
      <ProductCategorySelect
        categories={productCategoryListNodes}
        subCategories={productSubCategoryListNodes}
        subCategories3={productSubCategory3ListNodes}
        state={productCategory}
        loadingCategories={productCategoryListLoading}
        loadingSubCategories={productSubCategoryListLoading}
        loadingSubCategories3={productSubCategory3ListLoading}
        enableQuickSearch={false}
        enableSetCategoryButton={false}
        onChange={handleProductCategoryChange}
        onHandleSetCategory={() => {}}
      />
      <SicCodesTree
        data={sicCodes}
        loading={loading}
        selectedSicCodes={selectedSicCodes}
        sicCodeOpenAI={openAISicCode}
        onCheckboxChange={setSelectedSicCodes}
      />
      <Box mt={2}>
        <SicCodeOpenAi onSicCodeChange={handleSetOpenAISicCode} />
      </Box>
      {companiesByIndustryAndCategory && (
        <DataGrid
          paginationMode="client"
          loading={companiesByIndustryAndCategoryLoading}
          disableRowSelectionOnClick
          rows={companiesByIndustryAndCategory?.companiesByIndustryAndCategory || []}
          columns={companiesColumns}
          rowHeight={35}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
      )}
    </Box>
  );
};

export default MatrixSearchPage;
