import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import CustomFooter from '../../../../components/Footer/CustomFooter';
import {
  useCreateKeywordsMutation,
  useCreateUpdateKeywordMutation,
  useDeleteKeywordMutation,
  useDeleteKeywordsMutation,
  useGetKeywordsForCompanyQuery,
} from '../../../../gql/generated/graphql';

import { validateKeywordsStyles } from './styles';

type Props = {
  companyId: number;
};

const ValidateKeywords = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const [additionalKeyword, setAdditionalKeyword] = useState('');
  const [selectedKeywords, setSelectedKeywords] = useState<number[]>([]);

  const {
    data: keywordsForCompanyData,
    loading: keywordsForCompanyLoading,
    refetch: refetchKeywordsForCompany,
  } = useGetKeywordsForCompanyQuery({ variables: { companyId } });
  const keywordsForCompany = keywordsForCompanyData?.getCompany?.edges
    ?.flatMap((edge) => edge?.node?.crawlResults?.edges)
    ?.flatMap((crawlEdge) => crawlEdge?.node?.xoviKeywords?.edges)
    ?.map((node) => node?.node);

  const genericCrawlResultId =
    keywordsForCompanyData?.getCompany?.edges?.[0]?.node?.crawlResults?.edges?.[0]?.node?.dbId;

  const [deleteKeyword, { loading: deleteKeywordLoading }] = useDeleteKeywordMutation();
  const [deleteKeywords, { loading: deleteKeywordsLoading }] = useDeleteKeywordsMutation();
  const [createUpdateKeyword, { loading: createUpdateKeywordLoading }] =
    useCreateUpdateKeywordMutation();
  const [createKeywords, { loading: createKeywordsLoading }] = useCreateKeywordsMutation();

  const productCategoryColumns = [
    {
      field: 'keyword',
      width: 200,
      headerName: t('validation.validateKeyword.keywordColumnLabel'),
    },
    {
      field: 'searchEngine',
      headerName: t('validation.validateKeyword.searchEngineColumnLabel'),
    },
    {
      field: 'position',
      headerName: t('validation.validateKeyword.positionColumnLabel'),
    },
    {
      field: 'cpc',
      headerName: t('validation.validateKeyword.cpcColumnLabel'),
    },
    {
      field: 'sevo',
      headerName: t('validation.validateKeyword.sevoColumnLabel'),
    },
    {
      field: 'competitorDensity',
      headerName: t('validation.validateKeyword.densityColumnLabel'),
    },
    {
      field: 'markedAsIrrelevant',
      headerName: t('validation.validateKeyword.importantColumnLabel'),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {!params?.formattedValue ? <DoneIcon fontSize="small" /> : null}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton size="small" onClick={() => handleDelete(params.row)}>
            <Tooltip title={t('validation.validateKeyword.deleteButtonTooltip')}>
              <DeleteOutlineIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const handleDelete = async (row) => {
    const result = await deleteKeyword({
      variables: {
        keywordId: row.dbId,
      },
    });
    if (result.data?.deleteKeyword?.success) {
      await refetchKeywordsForCompany();
    }
  };

  const handleDeleteSelected = async (ids: number[]) => {
    const result = await deleteKeywords({
      variables: {
        keywordIds: ids,
      },
    });
    if (result.data?.deleteKeywords?.success) {
      await refetchKeywordsForCompany();
      setSelectedKeywords([]);
    }
  };

  const handleAddKeywords = async () => {
    if (!genericCrawlResultId) return;

    if (additionalKeyword.includes(',')) {
      const multipleKeywords = additionalKeyword
        .split(',')
        .map((keyword) => keyword.trim())
        .filter((keyword) => keyword);
      const result = await createKeywords({
        variables: {
          genericCrawlResultId,
          keywords: multipleKeywords,
        },
      });
      if (result?.data?.createKeywords?.genericCrawlResultId) {
        await refetchKeywordsForCompany();
      }
    } else {
      const singleKeyword = additionalKeyword.trim();
      const result = await createUpdateKeyword({
        variables: {
          genericCrawlResultId,
          keyword: singleKeyword,
        },
      });
      if (result?.data?.createUpdateKeyword?.keyword) {
        await refetchKeywordsForCompany();
      }
    }
    setAdditionalKeyword('');
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <DataGrid
        sx={validateKeywordsStyles.dataGridMargin}
        disableRowSelectionOnClick
        rows={keywordsForCompany || []}
        loading={keywordsForCompanyLoading}
        columns={productCategoryColumns}
        rowHeight={35}
        initialState={{
          sorting: {
            sortModel: [{ field: 'cpc', sort: 'desc' }],
          },
          pagination: {
            paginationModel: {
              pageSize: 80,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 80]}
        checkboxSelection
        getRowId={(row) => row?.dbId}
        rowSelectionModel={selectedKeywords}
        onRowSelectionModelChange={(ids: number[]) => setSelectedKeywords(ids)}
        slots={{
          footer: () => (
            <CustomFooter
              onRefresh={() => refetchKeywordsForCompany()}
              onDeleteSelected={() => handleDeleteSelected(selectedKeywords)}
            />
          ),
        }}
      />
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <TextField
            label={t('validation.validateKeyword.additionalKeywordsTextfieldLabel')}
            id="additional_keywords"
            fullWidth
            value={additionalKeyword}
            onChange={(e) => setAdditionalKeyword(e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <Button onClick={handleAddKeywords} variant="contained">
            {t('validation.validateKeyword.addButton')}
          </Button>
        </Grid>
      </Grid>
      <CustomBackdrop
        open={
          deleteKeywordLoading ||
          deleteKeywordsLoading ||
          createUpdateKeywordLoading ||
          createKeywordsLoading
        }
      />
    </Box>
  );
};

export default ValidateKeywords;
