import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  uploadButton: {
    flexGrow: 1,
  },
  linearProgress: {
    width: '100%',
  },
  divider: {
    my: 2,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

export default styles;
