import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import SicCodeOpenAi from '../../components/SicCodeOpenAi/SicCodeOpenAi';
import SicCodesTree from '../../components/SicCodesTree/SicCodesTree';
import {
  useCompaniesByIndustriesLazyQuery,
  useGetAllSicCodesQuery,
} from '../../gql/generated/graphql';
import { useCompanyNavigation } from '../../hooks/useCompanyNavigation';
import { getResearchCompaniesColumns } from '../../utils/getResearchCompaniesColumns';

const DEBOUNCE_DELAY = 1000;

const CustomerIndustriesPage = () => {
  const { t } = useTranslation();
  const { handleValidate, handleNavigateToAssesment } = useCompanyNavigation();
  const [selectedSicCodes, setSelectedSicCodes] = useState<string[]>([]);
  const [openAISicCode, setOpenAISicCode] = useState<string>('');

  const { data: sicCodes, loading } = useGetAllSicCodesQuery();
  const [
    getCompaniesByIndustries,
    { data: companiesByIndustries, loading: companiesByIndustriesLoading },
  ] = useCompaniesByIndustriesLazyQuery();

  const getCompaniesByIndustriesDebounced = useDebouncedCallback((values: string[]) => {
    getCompaniesByIndustries({ variables: { sicCodes: selectedSicCodes } });
  }, DEBOUNCE_DELAY);

  useEffect(() => {
    if (selectedSicCodes.length > 0) {
      getCompaniesByIndustriesDebounced(selectedSicCodes);
    }
  }, [selectedSicCodes]);

  const handleSetOpenAISicCode = (code: string) => {
    setOpenAISicCode(code);
  };

  const companiesColumns = getResearchCompaniesColumns(handleValidate, handleNavigateToAssesment);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography mb={2} variant="h6">
        {t('customerIndustriesPage.title')}
      </Typography>
      <SicCodesTree
        data={sicCodes}
        loading={loading}
        selectedSicCodes={selectedSicCodes}
        sicCodeOpenAI={openAISicCode}
        onCheckboxChange={setSelectedSicCodes}
      />
      <Box mt={2}>
        <SicCodeOpenAi onSicCodeChange={handleSetOpenAISicCode} />
      </Box>
      {companiesByIndustries && (
        <DataGrid
          paginationMode="client"
          loading={companiesByIndustriesLoading}
          disableRowSelectionOnClick
          rows={companiesByIndustries?.companiesByIndustries || []}
          columns={companiesColumns}
          rowHeight={35}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
      )}
    </Box>
  );
};

export default CustomerIndustriesPage;
