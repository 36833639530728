export const ensureProtocol = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

export const extractKeywords = (data) => {
  if (!data) return [];
  return data?.getCompany?.edges?.[0]?.node?.crawlResults?.edges
    ?.flatMap((edge) => edge?.node?.xoviKeywords)
    ?.flatMap((edge) => edge?.edges)
    ?.map((node) => ({ id: node?.node?.id, keyword: node?.node?.keyword }));
};

export const handleVisitPage = (webUrl) => {
  const url = ensureProtocol(webUrl);
  window.open(url, '_blank');
};
