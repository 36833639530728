import { useNavigate } from 'react-router-dom';

import { paths } from '../routes/paths';

export const useCompanyNavigation = () => {
  const navigate = useNavigate();

  const handleValidate = (companyId: number) => {
    navigate(`${paths.validation}?companyId=${companyId}`);
  };

  const handleNavigateToAssesment = (companyId: number) => {
    navigate(`${paths.evaluation.dfm}?companyId=${companyId}`);
  };

  return { handleValidate, handleNavigateToAssesment };
};
