import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  paper: {
    border: '1px solid #d5cbc1',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: 1,
    p: 2,
    position: 'relative', // Added relative positioning for the paper
  },
  editButton: {
    position: 'absolute', // Position the button absolutely within the paper
    width: 'auto',
    height: 'auto',
    top: 8, // Adjust this value to control vertical offset
    right: 8, // Adjust this value to control horizontal offset
  },
};

export default styles;
