import { ChangeEventHandler, HTMLInputTypeAttribute } from 'react';

import { TextField } from '@mui/material';

import styles from './styles';
type Props = {
  name: string;
  label: string;
  value: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const CustomTextField = ({
  name,
  label,
  value,
  required = false,
  type = 'text',
  onChange,
}: Props) => {
  return (
    <TextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      fullWidth
      sx={styles.textField}
      size="small"
      required={required}
      type={type}
    />
  );
};

export default CustomTextField;
