import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

import i18n from '../locales/i18n';

type Severity = 'info' | 'success' | 'warning' | 'error';

interface SnackbarState {
  open: boolean;
  message: string;
  severity: Severity;
}

interface SnackbarContextProps {
  showSnackbar: (message: string, severity?: Severity, showFriendlyMessage?: boolean) => void;
}

const getFriendlyErrorMessage = (message: string): string => {
  if (message.includes('Network')) {
    return i18n.t('errors.networkErrorMessage');
  }
  if (message.includes('GraphQL')) {
    return i18n.t('errors.graphqlErrorMessage');
  }
  if (message.includes('VerificationSession')) {
    return i18n.t('errors.verificationlErrorMessage');
  }
  return i18n.t('errors.undefinedErrorMessage');
};

const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined);

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'info',
  });

  const showSnackbar = (
    message: string,
    severity: Severity,
    showFriendlyMessage: boolean = true,
  ) => {
    const friendlyMessage = showFriendlyMessage ? getFriendlyErrorMessage(message) : message;
    setSnackbar({ open: true, message: friendlyMessage, severity });
  };

  const handleClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    window.__APOLLO_GLOBAL_ERROR_HANDLER__ = showSnackbar;
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextProps => {
  const context = React.useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
