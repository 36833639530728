import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import styles from './styles';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
      <Typography variant="h5">{t('notFound')}</Typography>
      <Button sx={styles.button} component={Link} to="/">
        {t('backToHome')}
      </Button>
    </Box>
  );
};

export default NotFound;
