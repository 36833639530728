// src/PendoScriptInjector.tsx

import React, { useEffect } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';

const PendoScriptInjector: React.FC = () => {
  const { user } = useAuthenticator(); // Extract user info

  useEffect(() => {
    if (user) {
      // Create a script element
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
      (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

        pendo.initialize({
            visitor: {
                id: '${user.userId}', // Access user attributes like username, email, etc
            },
            account: {
                id: '<account-id-goes-here>'
            }
        });
      })('68c58f0f-bb08-4c8a-5211-acba5e07c0c8');
      `;

      // Append the script to the document head
      document.head.appendChild(script);
    }
  }, [user]); // This effect will run when the user data is available

  return null; // No UI needed for this component
};

export default PendoScriptInjector;
