import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Button, Container, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as Sentry from '@sentry/react';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import { PD_URL } from '../../../../contants';
import {
  CompanyType,
  GetCompanyDocument,
  GetCompanyQuery,
  useEditCompanyMutation,
  useGetCompanyQuery,
  useOpenregCompaniesLazyQuery,
} from '../../../../gql/generated/graphql';
import { getFirstNode } from '../../../../gql/helpers';
import CustomLink from '../../../DFMPage/components/CustomLink/CustomLink';
import CustomTextField from '../CustomTextField/CustomTextField';

import { validateAddressStyles } from './styles';

type Props = {
  companyId: number | null;
  onNextStep: () => void;
};

const ValidateAddressStep = ({ companyId, onNextStep }: Props) => {
  const formValuesRef = useRef(null);

  const { data } = useGetCompanyQuery({
    variables: { companyId },
  });

  const [getOpenregCompanies, { data: openregCompaniesData, loading: openregCompaniesLoading }] =
    useOpenregCompaniesLazyQuery();

  const companyAddress: CompanyType = getFirstNode(data?.getCompany);
  const openregCompaniesNodes =
    openregCompaniesData?.getCompany?.edges?.[0]?.node?.openregCompanies?.edges?.map(
      (node) => node?.node,
    );

  const [editCompany, { loading: isEditCompanyLoading }] = useEditCompanyMutation();
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    legalName: '',
    shortName: '',
    street: '',
    city: '',
    zipCode: '',
    country: '',
    linkedinUrl: '',
    hrbNumber: '',
    court: '',
    benchmarkingIndex: '',
    sicCode: '',
    foundingYear: '',
    pipedriveId: '',
  });

  useEffect(() => {
    if (companyAddress) {
      const values = {
        legalName: companyAddress?.legalName || '',
        shortName: companyAddress?.shortName || '',
        street: companyAddress?.street || '',
        city: companyAddress?.city || '',
        zipCode: companyAddress?.zipCode || '',
        country: companyAddress?.country || '',
        linkedinUrl: companyAddress?.linkedinUrl || '',
        hrbNumber: companyAddress?.hrbNumber || '',
        court: companyAddress?.court || '',
        benchmarkingIndex: companyAddress?.benchmarkingIndex?.toString() || '',
        sicCode: companyAddress?.sicCode || '',
        foundingYear: companyAddress.foundingYear?.toString() || '',
        pipedriveId: companyAddress?.pipedriveId?.toString() || '',
      };
      setFormValues(values);
      formValuesRef.current = values;
    }
  }, [companyAddress]);

  useEffect(() => {
    if (!companyId) return;
    getOpenregCompanies({ variables: { companyId } });
  }, [companyId]);

  const openregCompaniesColumns = [
    {
      field: 'companyName',
      headerName: t('validation.validateAddress.companiesColumnsLegalName'),
      width: 150,
    },
    {
      field: 'addressStreet',
      headerName: t('validation.validateAddress.companiesColumnsStreet'),
      width: 80,
    },
    {
      field: 'addressPlace',
      headerName: t('validation.validateAddress.companiesColumnsCity'),
      width: 80,
    },
    {
      field: 'addressZipcode',
      headerName: t('validation.validateAddress.companiesColumnsZipCode'),
      width: 80,
    },
    {
      field: 'referenceCourtCode',
      headerName: t('validation.validateAddress.companiesColumnsHrbNumber'),
      width: 100,
    },
    {
      field: 'referenceCourtName',
      headerName: t('validation.validateAddress.companiesColumnsCourt'),
      width: 100,
    },
    {
      field: 'foundedDate',
      headerName: t('validation.validateAddress.companiesColumnsFoundingYear'),
      width: 100,
      valueGetter: (param) => {
        return param?.slice(0, 4);
      },
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton size="small" onClick={() => handleCopyAddress(params.row)}>
          <Tooltip title={'Copy'}>
            <ContentCopyOutlinedIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const result = await editCompany({
        variables: {
          companyId,
          ...formValues,
          benchmarkingIndex: parseInt(formValues?.benchmarkingIndex),
          pipedriveId: parseInt(formValues?.pipedriveId),
          foundingYear: parseInt(formValues?.foundingYear),
        },
        update: (cache, { data: { editCompany } }) => {
          const existingData = cache.readQuery<GetCompanyQuery>({
            query: GetCompanyDocument,
            variables: { companyId },
          });

          if (existingData?.getCompany?.edges) {
            const updatedEdges = existingData.getCompany.edges.map((edge) => {
              if (edge.node.id === companyAddress?.id) {
                return {
                  ...edge,
                  node: {
                    ...edge.node,
                    ...editCompany,
                  },
                };
              }
              return edge;
            });

            cache.writeQuery({
              query: GetCompanyDocument,
              variables: { companyId },
              data: {
                getCompany: {
                  ...existingData.getCompany,
                  edges: updatedEdges,
                },
              },
            });
          }
        },
      });

      if (result.data.editCompany) {
        onNextStep();
      }
    } catch (error) {
      console.error('useEditCompanyMutation error:', error);
      Sentry.captureException(error);
    }
  };

  const handleCopyAddress = (row) => {
    setFormValues((prev) => ({
      ...prev,
      legalName: row.companyName || '',
      street: row.addressStreet || '',
      city: row.addressPlace || '',
      zipCode: row.addressZipcode || '',
      hrbNumber: row.referenceCourtCode || '',
      court: row.referenceCourtName || '',
      foundingYear: row.foundedDate || '',
    }));
  };

  const handleUndo = () => {
    setFormValues(formValuesRef.current);
  };

  const isFormEmpty = ['legalName', 'shortName', 'country'].some(
    (field) => formValues[field] === '',
  );

  const showDataGrid = openregCompaniesNodes?.length > 0 && companyAddress?.country === 'DE';

  return (
    <Box display="flex" gap={4} flexDirection={{ xs: 'column', md: 'row' }}>
      <Box flex={1}>
        <Typography mb={2} variant="h5">
          {t('validation.validateAddress.editCompany')}
        </Typography>
        <Container
          component="form"
          onSubmit={handleSubmit}
          disableGutters
          sx={validateAddressStyles.container}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CustomTextField
                name="legalName"
                label={t('validation.validateAddress.legalName')}
                value={formValues.legalName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="shortName"
                label={t('validation.validateAddress.shortName')}
                value={formValues.shortName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="street"
                label={t('validation.validateAddress.street')}
                value={formValues.street}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="city"
                label={t('validation.validateAddress.city')}
                value={formValues.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="zipCode"
                label={t('validation.validateAddress.zipCode')}
                value={formValues.zipCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="country"
                label={t('validation.validateAddress.country')}
                value={formValues.country}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="linkedinUrl"
                label={t('validation.validateAddress.linkedinUrl')}
                value={formValues.linkedinUrl}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="pipedriveId"
                label={t('validation.validateAddress.pipedriveId')}
                value={formValues.pipedriveId}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="hrbNumber"
                label={t('validation.validateAddress.hrbNumber')}
                value={formValues.hrbNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="court"
                label={t('validation.validateAddress.court')}
                value={formValues.court}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="benchmarkingIndex"
                label={t('validation.validateAddress.benchmarkingIndex')}
                value={formValues.benchmarkingIndex}
                onChange={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="sicCode"
                label={t('validation.validateAddress.sicCode')}
                value={formValues.sicCode}
                onChange={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="foundingYear"
                label="Founding year"
                value={formValues?.foundingYear}
                onChange={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12} display="flex" gap={4}>
              <CustomLink sx={validateAddressStyles.link} to={PD_URL + formValues?.pipedriveId}>
                {t('validation.validateAddress.pdLink')}
              </CustomLink>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isEditCompanyLoading || isFormEmpty}
              >
                {t('validation.validateAddress.saveButton')}
              </Button>
              {showDataGrid && (
                <Button variant="outlined" onClick={handleUndo}>
                  {t('validation.validateAddress.undoButton')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      {showDataGrid && (
        <DataGrid
          paginationMode="client"
          loading={openregCompaniesLoading}
          disableRowSelectionOnClick
          rows={openregCompaniesNodes || []}
          getRowId={(row) => row.dbId}
          columns={openregCompaniesColumns}
          rowHeight={35}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
      )}
      <CustomBackdrop open={isEditCompanyLoading} />
    </Box>
  );
};

export default ValidateAddressStep;
