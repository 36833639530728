import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Chip, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import { FLAG_URL } from '../../contants';
import {
  useGetSoftwareFirmsLazyQuery,
  useSearchCompanyForEvaluationLazyQuery,
} from '../../gql/generated/graphql';
import usePagination from '../../hooks/usePagination';
import { paths } from '../../routes/paths';
import CustomPaper from '../DFMPage/components/CustomPaper/CustomPaper';

import styles from './styles';

const EvaluationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const [
    search,
    { data: softwareFirms, loading: softwareFirmsLoading, fetchMore: fetchMoreSoftwareFirms },
  ] = useGetSoftwareFirmsLazyQuery();

  const [
    getCompanies,
    { data: companiesData, loading: getCompaniesLoading, fetchMore: fetchMoreGetCompanies },
  ] = useSearchCompanyForEvaluationLazyQuery();

  const {
    paginationModel: paginationModelSoftwareFirms,
    isLoading: isLoadingPaginationModelSoftwareFirms,
    handlePaginationChange: handlePaginationChangeSoftwareFirms,
  } = usePagination({
    fetchMore: fetchMoreSoftwareFirms,
    pageInfo: softwareFirms?.softwareFirms?.pageInfo,
  });

  const { paginationModel, isLoading, handlePaginationChange } = usePagination({
    fetchMore: fetchMoreGetCompanies,
    pageInfo: companiesData?.cortexFirms?.pageInfo,
  });

  useEffect(() => {
    if (!searchTerm) {
      getCompanies();
    }
  }, [searchTerm]);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    search({ variables: { searchTerm: value } });
  }, 500);

  const companiesNodes = companiesData?.cortexFirms?.edges?.map((node) => node?.node);
  const softwareFirmsNodes = softwareFirms?.softwareFirms?.edges?.map((node) => node?.node);

  const handleReset = () => {
    setSearchTerm('');
  };

  const handleShowInfo = (row) => {
    setDialogText(row.description);
    handleToggleDialog();
  };

  const handleToggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleStartDiscussion = (row) => {
    if (!row) return;
    navigate(`${paths.evaluation.dfm}?companyId=${row?.dbId}`);
  };

  const handleInputChange = (value: string) => {
    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    }
  };

  const searchColumnCategories = [
    {
      field: 'validated',
      headerName: t('evaluation.validateColumnField'),
      width: 100,
      renderCell: (params) => {
        return params?.formattedValue ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <DoneIcon fontSize="small" />
          </Box>
        ) : null;
      },
    },
    {
      field: 'shortName',
      headerName: t('evaluation.shortNameColumnField'),
      width: 200,
    },
    {
      field: 'uniqueDomain',
      headerName: t('evaluation.uniqueDomainColumnField'),
      width: 200,
    },
    {
      field: 'country',
      headerName: t('evaluation.countryColumnField'),
      width: 100,
      renderCell: (params) => {
        return params?.formattedValue ? (
          <img
            src={FLAG_URL(params?.formattedValue)}
            width={32}
            height={32}
            alt="Company country flag"
          />
        ) : (
          ''
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <IconButton size="small" onClick={() => handleShowInfo(params.row)}>
              <InfoOutlinedIcon />
            </IconButton>
            <IconButton size="small" onClick={() => handleStartDiscussion(params.row)}>
              <Chip
                color="secondary"
                label={t('evaluation.startDiscussionColumnFieldButtonLabel')}
                size="small"
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Box display="flex" gap={4} flexDirection="column">
      <TextField
        label={t('evaluation.searchbarPlaceholder')}
        variant="outlined"
        fullWidth
        onChange={(e) => handleInputChange(e.target.value)}
        value={searchTerm}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleReset} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CustomPaper sx={styles.customPaper}>
        <Typography variant="h6">{t('evaluation.topicsForDealflowMeetingTitle')}</Typography>
        {searchTerm ? (
          <DataGrid
            paginationMode="server"
            loading={softwareFirmsLoading || isLoadingPaginationModelSoftwareFirms}
            getRowId={(row) => row?.dbId}
            disableRowSelectionOnClick
            rows={softwareFirmsNodes || []}
            columns={searchColumnCategories}
            paginationModel={paginationModelSoftwareFirms}
            onPaginationModelChange={handlePaginationChangeSoftwareFirms}
            rowHeight={35}
            initialState={{
              pagination: {
                rowCount: -1,
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
          />
        ) : (
          <DataGrid
            paginationMode="server"
            loading={getCompaniesLoading || isLoading}
            getRowId={(row) => row?.dbId}
            disableRowSelectionOnClick
            rows={companiesNodes || []}
            columns={searchColumnCategories}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
            rowHeight={35}
            initialState={{
              pagination: {
                rowCount: -1,
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[1]}
          />
        )}
      </CustomPaper>
      <Dialog
        open={dialogOpen}
        onClose={handleToggleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('evaluation.dialogTitle')}
          <IconButton aria-label="close" onClick={handleToggleDialog} sx={styles.dialogCloseIcon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText ? dialogText : t('evaluation.emptyDialog')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EvaluationPage;
