import { ValidationProvider } from '../../contexts/ValidationContext';

import ValidationPage from './ValidationPage';

const ValidationWrapper = () => {
  return (
    <ValidationProvider>
      <ValidationPage />
    </ValidationProvider>
  );
};

export default ValidationWrapper;
