// @ts-nocheck

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';

import CompanyLinks from '../../components/CompanyLinks/CompanyLinks';
import CustomBackdrop from '../../components/CustomBackdrop/CustomBackdrop';
import SearchComponent from '../../components/Search/Search';
import TagsBox from '../../components/TagsBox/TagsBox';
import { FLAG_URL } from '../../contants';
import { useNotifications } from '../../contexts/NotificationsContext';
import {
  CompanyAssesmentEdge,
  CompanyAssessmentType,
  useCompetitionCpcKeywordsLazyQuery,
  useCustomerIndustryGrowthRatesLazyQuery,
  useDealAssessmentFileUrlLazyQuery,
  useEmployeeStaticsLazyQuery,
  useGetCompanyForDfmLazyQuery,
  useGetProductCategoriesForCompanyLazyQuery,
  useGetProgrammingLanguagesLazyQuery,
  useGetSoftwareFirmsLazyQuery,
  usePrecalculatedKpisLazyQuery,
  useSoftwareCategoryGrowthRatesLazyQuery,
  useSpecialCpcKeywordsLazyQuery,
  useTopCpcKeywordLazyQuery,
} from '../../gql/generated/graphql';
import { getFirstNode, getNodes } from '../../gql/helpers';
import { extractKeywords } from '../../helpers/helpers';
import useResponsive from '../../hooks/useResponsive';
import { paths } from '../../routes/paths';
import { ValidationSteps } from '../ValidationPage/types';

import CompanyHeader from './components/CompanyHeader/CompanyHeader';
import CustomPaper from './components/CustomPaper/CustomPaper';
import EditButton from './components/EditButton/EditButton';
import {
  cpcCompetitionKeywordsColumns,
  customerIndustryGrowthColumns,
  employeStaticsColumns,
  precalculatedFinanceKpisColumns,
  precalculatedOnlineKpisColumns,
  precalculatedTechKpisColumns,
  softwareGrowthRatesColumns,
} from './helpers';

import styles from './styles';

const DFMPage = () => {
  const navigate = useNavigate();
  const { notifications } = useNotifications();
  const { t } = useTranslation();
  const { sm } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();
  const [options, setOptions] = useState<CompanyAssesmentEdge[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [company, setCompany] = useState<CompanyAssessmentType | null>(null);
  const [isGeneratingFile, setIsGeneratingFile] = useState(false);

  const companyParamsId = searchParams.get('companyId');

  const [
    getSoftwareGrowthRates,
    { data: softwareGrowthRates, loading: softwareGrowthRatesLoading },
  ] = useSoftwareCategoryGrowthRatesLazyQuery();
  const [
    getCompetitionCpcKeywords,
    { data: competitionCpcKeywords, loading: competitionCpcKeywordsLoading },
  ] = useCompetitionCpcKeywordsLazyQuery();
  const [
    getProgrammingLanguages,
    { data: programmingLanguages, loading: getProgrammingLanguagesLoading },
  ] = useGetProgrammingLanguagesLazyQuery();
  const [getEmployeeStatics, { data: employeeStatics, loading: employeeStaticsLoading }] =
    useEmployeeStaticsLazyQuery();
  const [
    getProductCategoriesForCompany,
    { data: productCategoriesForCompany, loading: productCategoriesForCompanyLoading },
  ] = useGetProductCategoriesForCompanyLazyQuery();
  const [
    getCustomerIndustryGrowthRates,
    { data: customerIndustryGrowthRates, loading: customerIndustryGrowthRatesLoading },
  ] = useCustomerIndustryGrowthRatesLazyQuery();
  const [getTopCpcKeywords, { data: topCpcKeywords, loading: topCpcKeywordsLoading }] =
    useTopCpcKeywordLazyQuery();
  const [getSpecialCpcKeywords, { data: specialCpcKeywords, loading: specialCpcKeywordsLoading }] =
    useSpecialCpcKeywordsLazyQuery();
  const [getCompanyForDFM, { data: companyForDFM, loading: companyForDFMLoading }] =
    useGetCompanyForDfmLazyQuery();
  const [getPrecalculatedKpis, { data: precalculatedKpis, loading: precalculatedKpisLoading }] =
    usePrecalculatedKpisLazyQuery();
  const [
    getDealAssessmentFileUrl,
    { data: dealAssessmentFileUrl, refetch: dealAssessmentFileUrlRefetch },
  ] = useDealAssessmentFileUrlLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const dealAssessmentFile = data.getCompany?.edges?.[0]?.node?.dealAssessmentFileUrl;
      if (!dealAssessmentFile?.downloadUrl && dealAssessmentFile?.generationTriggered) {
        setIsGeneratingFile(true);
      }
    },
  });

  useEffect(() => {
    let options = {};
    if (companyParamsId) {
      options = {
        variables: {
          companyId: companyParamsId,
        },
        fetchPolicy: 'no-cache',
      };
    } else {
      if (!company) return;

      options = {
        variables: {
          companyId: company?.dbId,
        },
        fetchPolicy: 'no-cache',
      };
    }

    getSoftwareGrowthRates(options);
    getCompetitionCpcKeywords(options);
    getProgrammingLanguages(options);
    getEmployeeStatics(options);
    getProductCategoriesForCompany(options);
    getCustomerIndustryGrowthRates(options);
    getTopCpcKeywords(options);
    getSpecialCpcKeywords(options);
    getCompanyForDFM(options);
    getDealAssessmentFileUrl(options);
    getPrecalculatedKpis(options);
  }, [company]);

  const softwareGrowthRatesNodes = getNodes(softwareGrowthRates?.softwareCategoryGrowthRates);
  const competitionCpcKeywordsNodes = getNodes(competitionCpcKeywords?.competitionCpcKeywords);
  const programmingLanguagesNodes = getNodes(programmingLanguages?.getProgrammingLanguages);
  const employeeStaticsNodes = getNodes(employeeStatics?.employeeStatics);
  const productCategoriesForCompanyNodes =
    productCategoriesForCompany?.getCompany?.edges?.[0]?.node?.productCategorization?.edges?.map(
      (edge) => edge.node.softwareCategory,
    ) || [];
  const customerIndustryGrowthRatesNodes = getNodes(
    customerIndustryGrowthRates?.customerIndustryGrowthRates,
  );
  const companyForDFMNode = getFirstNode(companyForDFM?.getCompany);
  const topCpcKeywordsNodes = extractKeywords(topCpcKeywords);
  const specialCpcKeywordsNodes = extractKeywords(specialCpcKeywords);
  const dealAssessmentFile =
    dealAssessmentFileUrl?.getCompany?.edges?.[0]?.node?.dealAssessmentFileUrl;
  const precalculatedKpisNodes =
    precalculatedKpis?.companyAssesment?.edges?.[0]?.node?.precalculatedKpis?.edges?.map(
      (node) => node?.node,
    );

  useEffect(() => {
    if (dealAssessmentFile && dealAssessmentFile?.downloadUrl) {
      const { downloadUrl, generationTriggered } = dealAssessmentFile;
      if (!downloadUrl && generationTriggered) {
        const searchString = "{status: 'Finished', task_name: 'generate_deal_assessment_file";

        const finishedGeneratingFile = notifications.some(
          (obj: { message: string; read: boolean; timestamp: Date }) =>
            obj.message.includes(searchString),
        );
        if (finishedGeneratingFile) {
          setIsGeneratingFile(false);
          dealAssessmentFileUrlRefetch();
        }
      }
    }
  }, [isGeneratingFile, notifications]);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    search({ variables: { searchTerm: value } });
  }, 500);

  const [search, { loading }] = useGetSoftwareFirmsLazyQuery({
    onCompleted: (data) => {
      setOptions(data.softwareFirms.edges);
    },
  });

  const handleOptionSelect = (_, value: CompanyAssesmentEdge | null) => {
    setCompany(value ? value.node : null);
    setSearchParams({ companyId: value?.node?.dbId?.toString() });
  };

  const handleInputChange = (_, value: string, reason: string) => {
    if (reason === 'clear') {
      handleReset();
      search();
      return;
    }

    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    }
  };
  const handleReset = () => {
    setSearchTerm('');
    setOptions([]);
    setCompany(null);
  };

  const handleDownloadFileFromUrl = (url: string) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  const handleEdit = (step: ValidationSteps) => {
    navigate(`${paths.validation}?companyId=${companyParamsId}&step=${step}`);
  };

  const companyName =
    (companyForDFMNode?.shortName !== '<unnamed reference customer>' &&
      companyForDFMNode?.shortName) ||
    companyForDFMNode?.legalName ||
    companyForDFMNode?.uniqueDomain;

  const companyAnalyses = companyForDFMNode?.companyAnalyses?.edges?.[0]?.node;

  const mobilePaperStyle = sm && styles.mobilePaper;

  const employeeConcentrationRatio = precalculatedKpisNodes?.[0]?.employeeConcentrationRatio
    ? `${(precalculatedKpisNodes?.[0]?.employeeConcentrationRatio * 100).toFixed(2)}%`
    : null;

  const callabilityScore = precalculatedKpisNodes?.[0]?.callabilityScore?.toFixed(2);
  const showCallabilityScore =
    callabilityScore !== null && callabilityScore !== undefined && callabilityScore >= 0;
  const generationTime = dealAssessmentFile?.dateOfFile
    ? format(new Date(dealAssessmentFile?.dateOfFile), 'dd MMMM yyyy HH:mm')
    : null;

  return (
    <Box>
      <SearchComponent
        options={options}
        loading={loading}
        searchTerm={searchTerm}
        onInputChange={handleInputChange}
        onOptionSelect={handleOptionSelect}
        onReset={handleReset}
      />
      {companyForDFMNode && (
        <Box mt={2} gap={2} display="flex" flexDirection="column">
          <CompanyHeader companyName={companyName} isRelevant={companyForDFMNode?.isRelevant} />
          <CustomPaper>
            <Stack
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 2, md: 8 }}
              width="100%"
            >
              <Box>
                <Box
                  gap={2}
                  display="flex"
                  alignItems={{ xs: 'flex-start', md: 'center' }}
                  flexDirection={{ xs: 'column', md: 'row' }}
                >
                  <Box
                    display="flex"
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                    flexDirection="column"
                  >
                    {companyForDFMNode?.country && (
                      <img
                        src={FLAG_URL(companyForDFMNode?.country)}
                        width={64}
                        height={64}
                        alt="Company country flag"
                      />
                    )}
                    <Box display="flex">
                      <Box
                        display="flex"
                        alignItems={{ xs: 'flex-start', md: 'center' }}
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <Typography fontWeight="medium">{t('assessment.fte')}</Typography>
                        <Typography>{companyAnalyses?.fte}</Typography>
                      </Box>
                      <IconButton
                        color="inherit"
                        aria-label="edit"
                        onClick={() => handleEdit(ValidationSteps.VALIDATE_SIZE)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Box>
                    <Typography mt={2} variant="h5">
                      {companyForDFMNode?.legalName || companyName}
                    </Typography>
                  </Box>
                </Box>
                <Typography>
                  {companyForDFMNode?.street} {companyForDFMNode?.zipCode} {companyForDFMNode?.city}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.linkedinDescription}>
                  {companyForDFMNode?.linkedinDescription}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Chip
                  color={companyForDFMNode?.validated ? 'success' : 'primary'}
                  label={
                    companyForDFMNode?.validated
                      ? t('assessment.validated')
                      : t('assessment.notValidated')
                  }
                />
                {showCallabilityScore && (
                  <Gauge
                    width={80}
                    height={80}
                    value={callabilityScore * 100}
                    startAngle={-90}
                    endAngle={90}
                    text={`${callabilityScore * 100} %`}
                  />
                )}
              </Box>
              <Box sx={styles.editButton}>
                <EditButton onPress={handleEdit} step={ValidationSteps.VALIDATE_ADDRESS} />
              </Box>
            </Stack>
          </CustomPaper>
          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <EditButton onPress={handleEdit} step={ValidationSteps.VALIDATE_KEYWORDS} />
            <Grid container spacing={1}>
              <Grid item md={2} xs={12}>
                <CompanyLinks
                  uniqueDomain={companyForDFMNode?.uniqueDomain}
                  linkedinUrl={companyForDFMNode?.linkedinUrl}
                  pipedriveID={companyForDFMNode?.pipedriveId}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                {companyForDFMNode?.iabDomainCategory && (
                  <Chip
                    sx={styles.domainCategoryChip}
                    color="success"
                    label={companyForDFMNode?.iabDomainCategory}
                  />
                )}
              </Grid>
              <Grid item md={5} xs={12}>
                <TagsBox tags={[...topCpcKeywordsNodes, ...specialCpcKeywordsNodes]} />
              </Grid>
            </Grid>
          </CustomPaper>
          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              flex={1}
            >
              <Box display="flex" alignItems="center" flex={1} gap={2}>
                <Typography color="primary.main" fontWeight="bold">
                  {t('assessment.systemType')}
                </Typography>
                <EditButton onPress={handleEdit} step={ValidationSteps.VALIDATE_PRODUCT_CATEGORY} />
              </Box>
              {productCategoriesForCompanyLoading ? (
                <CircularProgress />
              ) : (
                <Box gap={0.5} sx={styles.systemTypeChipBox}>
                  {productCategoriesForCompanyNodes?.map((tag) => {
                    if (tag?.subCategory1 === null) return null;
                    return (
                      <Chip
                        sx={styles.keyTechnologyChip}
                        color="error"
                        key={tag?.dbId}
                        label={tag?.subCategory1}
                      />
                    );
                  })}
                </Box>
              )}
            </Box>
          </CustomPaper>

          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Typography color="primary.main" fontWeight="bold">
              {t('assessment.financeKpisTitle')}
            </Typography>
            <DataGrid
              rows={precalculatedKpisNodes}
              columns={precalculatedFinanceKpisColumns}
              loading={precalculatedKpisLoading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </CustomPaper>

          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Box display="flex" gap={2} alignItems="center">
              <Typography color="primary.main" fontWeight="bold">
                {t('assessment.softwareCategoryGrowth')}
              </Typography>
              <EditButton onPress={handleEdit} step={ValidationSteps.VALIDATE_PRODUCT_CATEGORY} />
            </Box>

            <DataGrid
              columnVisibilityModel={{
                interest: !sm,
                lastInterest: !sm,
                alltimeInterest: !sm,
              }}
              rows={softwareGrowthRatesNodes}
              // TODO: backend is returning null for dbId so just for now I am generating random id's. NEEDS to be fixed on the backend!
              getRowId={(row) => row?.dbId || Math.random()}
              loading={softwareGrowthRatesLoading}
              columns={softwareGrowthRatesColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </CustomPaper>
          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography color="primary.main" fontWeight="bold">
                {t('assessment.customerIndustryGrowthTitle')}
              </Typography>
              <EditButton onPress={handleEdit} step={ValidationSteps.VALIDATE_CUSTOMERS} />
            </Box>
            <DataGrid
              rows={customerIndustryGrowthRatesNodes}
              columns={customerIndustryGrowthColumns}
              getRowId={(row) => row?.dbId}
              loading={customerIndustryGrowthRatesLoading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </CustomPaper>
          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography color="primary.main" fontWeight="bold">
                {t('assessment.competition')}
              </Typography>
              <EditButton onPress={handleEdit} step={ValidationSteps.VALIDATE_KEYWORDS} />
            </Box>
            <DataGrid
              rows={competitionCpcKeywordsNodes}
              columns={cpcCompetitionKeywordsColumns}
              getRowId={(row) => row?.dbId}
              loading={competitionCpcKeywordsLoading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </CustomPaper>
          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Typography color="primary.main" fontWeight="bold">
              {t('assessment.onlineKpisTitle')}
            </Typography>
            <DataGrid
              rows={precalculatedKpisNodes}
              columns={precalculatedOnlineKpisColumns}
              loading={precalculatedKpisLoading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </CustomPaper>
          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Typography color="primary.main" fontWeight="bold">
              {t('assessment.employeeConcentrationRatioTitle')}
            </Typography>
            <Typography color="primary.accent" fontWeight="bold">
              {employeeConcentrationRatio}
            </Typography>
          </CustomPaper>
          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Typography color="primary.main" fontWeight="bold">
              {t('assessment.buyAndBuildScoreTitle')}
            </Typography>
            <Typography color="primary.accent" fontWeight="bold">
              {precalculatedKpisNodes?.[0]?.buyAndBuildScore}
            </Typography>
          </CustomPaper>
          <CustomPaper sx={{ ...styles.keyTechnologyPaper, ...mobilePaperStyle }}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" flex={1}>
              <Box display="flex" alignItems="center" gap={2} flex={1}>
                <Typography color="primary.main" fontWeight="bold">
                  {t('assessment.keyTechnologyTitle')}
                </Typography>
                <EditButton onPress={handleEdit} step={ValidationSteps.VALIDATE_TECHNOLOGY} />
              </Box>
              {getProgrammingLanguagesLoading ? (
                <CircularProgress />
              ) : (
                <Box gap={0.5} sx={styles.keyTechnologyChipBox}>
                  {programmingLanguagesNodes?.map((tag) => (
                    <Chip
                      sx={styles.keyTechnologyChip}
                      color="error"
                      key={tag?.dbId}
                      label={tag?.name}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </CustomPaper>

          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Typography color="primary.main" fontWeight="bold">
              {t('assessment.technologyKpisTitle')}
            </Typography>
            <DataGrid
              rows={precalculatedKpisNodes}
              columns={precalculatedTechKpisColumns}
              loading={precalculatedKpisLoading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </CustomPaper>

          <CustomPaper sx={{ ...styles.paper, ...mobilePaperStyle }}>
            <Typography color="primary.main" fontWeight="bold">
              {`${t('assessment.teamQualityTitle')}`}
            </Typography>
            <Typography color="primary.accent" fontWeight="bold">
              {employeeStaticsNodes?.[0]?.teamQualityPercentage}
            </Typography>
            <DataGrid
              rows={employeeStaticsNodes}
              columns={employeStaticsColumns}
              loading={employeeStaticsLoading}
              getRowId={(row) => row?.dbId}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </CustomPaper>
          <Box textAlign="center">
            <Button
              onClick={() => handleDownloadFileFromUrl(dealAssessmentFile?.downloadUrl)}
              disabled={Boolean(dealAssessmentFile?.noDataUploaded)}
              variant="contained"
            >
              {isGeneratingFile ? (
                <Box gap={1} display="flex">
                  {t('assessment.downloadAssessmentLoadingButton')}
                  <CircularProgress size={20} />
                </Box>
              ) : (
                t('assessment.downloadAssessmentButton')
              )}
            </Button>
            {generationTime && (
              <Typography
                mt={2}
                variant="body2"
              >{`${t('assessment.dealAssessmentFileGenerationTime')} ${generationTime}`}</Typography>
            )}
          </Box>
        </Box>
      )}
      <CustomBackdrop open={companyForDFMLoading} />
    </Box>
  );
};

export default DFMPage;
