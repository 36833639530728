import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  editButton: {
    position: 'absolute', // Position the button absolutely within the paper
    width: 'auto',
    height: 'auto',
    top: 8, // Adjust this value to control vertical offset
    right: 8, // Adjust this value to control horizontal offset
  },
};

export default styles;
