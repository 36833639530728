import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
  },
  wrapper: {
    flexShrink: { sm: 0 },
  },
  menuIconButton: {
    display: { sm: 'none' },
    position: 'absolute',
    left: 10,
  },
  menuIconCloseButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  mainContentWrapper: {
    flexGrow: 1,
    p: 3,
    pt: 5,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  desktopDrawer: {
    display: { xs: 'none', sm: 'block' },
  },
  mobileDrawer: {
    display: { xs: 'block', sm: 'none' },
  },
  drawerLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    p: 2,
  },
  drawerListItemButton: {
    pl: 4,
  },
  drawerIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  drawerIcon: {
    fontSize: 4,
  },
  listItemButton: {
    gap: 1,
  },
  navbarIcon: {
    fontSize: 15,
  },
  notAuthenticatedWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },
};

export default styles;
