import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  domainCategoryChip: {
    maxWidth: { xs: '250px', md: '80%' },
  },
  mobilePaper: {
    width: '90vw',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  keyTechnologyPaper: {
    display: 'flex',
    flex: 1,
  },
  keyTechnologyChipBox: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  keyTechnologyChip: {
    fontWeight: 'medium',
  },
  systemTypeChipBox: {
    flex: 3,
    display: 'flex',
    flexWrap: 'wrap',
  },
  linkedinDescription: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 8,
  },
};

export default styles;
