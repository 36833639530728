import { ResourcesConfig } from 'aws-amplify';

export const awsExports: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_DOMAIN,
          scopes: ['openid'],
          redirectSignIn: [
            'http://localhost:3000/',
            'https://ai.bid.pe/',
            'https://cortex.bid.pe/',
          ],
          redirectSignOut: [
            'http://localhost:3000/',
            'https://ai.bid.pe/',
            'https://cortex.bid.pe/',
          ],
          responseType: 'code',
        },
      },
    },
  },
};
