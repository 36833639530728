import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

// import { Analytics } from '@aws-amplify/analytics';
// import amplifyconfig from './amplifyconfiguration.json';
// import { Amplify } from 'aws-amplify';
const environment = process.env.NODE_ENV || 'development';
const release = process.env.REACT_APP_GIT_SHA;
Sentry.init({
  dsn: 'https://9e028a0cef934dca9d02e529166bb1c0@o4504570771210240.ingest.us.sentry.io/4504570775011331',
  environment: environment, // how can we get this from the env?
  release: release, // how can we get this from the env?
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      includeReplay: true,
      showBranding: false,
      colorScheme: 'system',
      showEmail: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  // tracePropagationTargets: [
  //   /^\//,
  //   /^https:\/\/courcing\.bidequity\.ai\/graphql/
  // ],
  tracePropagationTargets: [
    'http://localhost:3000',
    /^https:\/\/cortex\.bid\.pe\//,
    /^https:\/\/ai\.bid\.pe\//,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});
// Sentry.setUser({
//   fullName: user?.attributes?.name,
//   email: user?.attributes?.email,
// });

(function () {
  try {
    var e =
        typeof window !== 'undefined'
          ? window
          : typeof global !== 'undefined'
            ? global
            : typeof globalThis !== 'undefined'
              ? globalThis
              : {},
      n = new e.Error().stack;
    if (n) {
      e._sentryDebugIds = e._sentryDebugIds || {};
      e._sentryDebugIds[n] = 'd4e7db15-645c-5a05-995e-4a948459de0f';
    }
  } catch (e) {}
})();

//# debugId=d4e7db15-645c-5a05-995e-4a948459de0f
// Amplify.configure(amplifyconfig); // need to configure with user pool instead of identity pool

// const analyticsConfig = {
//    AWSPinpoint: {
//      // Amazon Pinpoint App Client ID
//      appId: 'c24da50f12ed4575bbeee9ec323306c4',
//      // Amazon service region
//      region: 'eu-central-1',
//      mandatorySignIn: false,
//    },
//  };

// Analytics.configure(analyticsConfig);
