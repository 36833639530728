import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Button, CircularProgress, Grid, LinearProgress, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// import { record } from '@aws-amplify/analytics';
import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import { useValidation } from '../../../../contexts/ValidationContext';
import {
  CompanyType,
  useGetCompanyQuery,
  useGetOpenaiVotesQuery,
  useMarkCompanyAsNotSoftwareMutation,
  useStartVerificationSessionMutation,
} from '../../../../gql/generated/graphql';
import { getFirstNode } from '../../../../gql/helpers';
import { ensureProtocol } from '../../../../helpers/helpers';
import { paths } from '../../../../routes/paths';
import CustomLink from '../../../DFMPage/components/CustomLink/CustomLink';

import { isSoftwareStepStyles } from './styles';

type Props = {
  companyId: number;
};

const IsSoftwareStep = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyValidation, setCompanyValidation } = useValidation();
  const { user } = useAuthenticator();

  const { data: openaiVotes } = useGetOpenaiVotesQuery({
    variables: { companyId },
  });

  const { data: company, loading: companyLoading } = useGetCompanyQuery({
    variables: { companyId },
  });
  const [markCompanyAsNotSoftware, { loading: markCompanyAsNotSoftwareLoading }] =
    useMarkCompanyAsNotSoftwareMutation();

  const [startVerificationSession] = useStartVerificationSessionMutation();

  const companyNode: CompanyType = getFirstNode(company?.getCompany);
  const openaiVotesNode = getFirstNode(openaiVotes?.getOpenaiVotes);

  const authId = user?.userId;

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    // This is Helge fudging: If the session cannot be started, we jump back to the inbox
    const result = await startVerificationSession({ variables: { companyId, authId } });
    if (result?.data?.startCompanyVerificationSession?.success)
      setCompanyValidation({ isSoftware: event.target.value });
    else navigate(paths.crawlerInbox);
    // record({
    //   // this still fails due to the missing app id
    //   name: 'validation-started',
    // });
  };

  const handleMarkAsNotSoftware = async () => {
    const result = await markCompanyAsNotSoftware({ variables: { companyId } });
    if (!result?.data?.markCompanyAsNotSoftware?.ok) return;
    navigate(paths.crawlerInbox); // We need to display an error message here
  };

  const percentagesicCodeCertainty = (companyNode?.sicCodeCertainty * 100).toFixed(0) + '%';
  const isNotSoftware = companyValidation?.isSoftware === 'no';

  if (companyLoading)
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <FormControl>
              <FormLabel id="is-software">{t('validation.isSoftwareCompany')}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="is-software"
                name="is-software-company-group"
                value={companyValidation?.isSoftware}
                onChange={handleChange}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography>
              <Typography component="span" fontWeight="medium">
                {t('validation.sicCode')}
              </Typography>{' '}
              {/* TOOD: Add missing value here here */}
            </Typography>
            {(companyNode?.sicCodeCertainty ?? 0) >= 0 && (
              <>
                <LinearProgress variant="determinate" value={companyNode?.sicCodeCertainty * 100} />
                <Typography textAlign="end">{percentagesicCodeCertainty}</Typography>
              </>
            )}
            <CustomLink sx={isSoftwareStepStyles.link} to={companyNode?.uniqueDomain}>
              {companyNode?.uniqueDomain}
            </CustomLink>
            <Box>
              {openaiVotesNode?.softwareVotes !== null ? (
                <Typography>
                  <Typography component="span" fontWeight="medium">
                    {t('validation.softwareVotes')}{' '}
                  </Typography>
                  {openaiVotesNode?.softwareVotes}
                </Typography>
              ) : null}
              {openaiVotesNode?.serviceVotes !== null ? (
                <Typography>
                  <Typography component="span" fontWeight="medium">
                    {t('validation.serviceVotes')}{' '}
                  </Typography>
                  {openaiVotesNode?.serviceVotes}
                </Typography>
              ) : null}
              {openaiVotesNode?.undecidable !== null ? (
                <Typography>
                  <Typography component="span" fontWeight="medium">
                    {t('validation.undecidable')}{' '}
                  </Typography>
                  {openaiVotesNode?.undecidable}
                </Typography>
              ) : null}
            </Box>
          </Grid>
        </Grid>
        {isNotSoftware && (
          <Button onClick={handleMarkAsNotSoftware} variant="contained">
            {t('validation.markAsNotSoftwareButton')}
          </Button>
        )}
      </Box>
      <Box mt={2} sx={isSoftwareStepStyles.iframeBox}>
        <iframe
          width="100%"
          height="100%"
          title="validated company website"
          src={ensureProtocol(companyNode?.uniqueDomain)}
        />
      </Box>
      <CustomBackdrop open={markCompanyAsNotSoftwareLoading} />
    </>
  );
};

export default IsSoftwareStep;
