import { IconButton, Tooltip } from '@mui/material';

const CustomIconButton = ({ icon, tooltip, onClick, ariaLabel = 'button' }) => {
  return (
    <IconButton aria-label={ariaLabel} onClick={onClick}>
      <Tooltip title={tooltip}>{icon}</Tooltip>
    </IconButton>
  );
};

export default CustomIconButton;
